var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.stars !== null
    ? _c(
        "div",
        { staticClass: "stars", class: _vm.sizeClass },
        [
          _vm._l(_vm.stars, function (n) {
            return _c("i", { key: n, staticClass: "material-icons active" }, [
              _vm._v("star"),
            ])
          }),
          _vm._v(" "),
          _vm.stars === 0
            ? _c("span", [_vm._v("ohne Klassifizierung")])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }