var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "multiselect",
    {
      attrs: {
        options: _vm.subTitleOnly ? _vm.items2 : _vm.items,
        loading: _vm.isLoadingItems,
        "allow-empty": false,
        disabled: !_vm.otherServiceTypeID,
        "custom-label": _vm.subTitleOnly
          ? _vm.otherSubTitleOnlyLabel
          : _vm.otherLabel,
        "internal-search": false,
        placeholder: "Zusatzleistung auswählen",
        "track-by": "id",
        "group-values": "items",
        "group-label": "type",
        "select-label": "",
        "deselect-label": "Löschen",
      },
      on: { "search-change": _vm.otherServiceSearch },
      scopedSlots: _vm._u([
        {
          key: "option",
          fn: function (props) {
            return [
              _c("div", { staticClass: "columns" }, [
                _c("div", { staticClass: "column" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.subTitleOnly
                          ? _vm.otherSubTitleOnlyLabel(props.option)
                          : _vm.otherLabel(props.option)
                      ) +
                      "\n                " +
                      _vm._s(
                        props.option.menu && props.option.menu.price
                          ? "(" + props.option.menu.price.formatted + ")"
                          : ""
                      ) +
                      "\n                "
                  ),
                  props.option.menu && _vm.isHover === props.option.id
                    ? _c(
                        "div",
                        {
                          staticStyle: { right: "-10px", position: "relative" },
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(props.option.menu.content),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                props.option.menu && props.option.menu.content
                  ? _c(
                      "button",
                      {
                        staticClass: "column is-narrow",
                        attrs: { title: "Preview" },
                        on: {
                          mouseenter: function ($event) {
                            _vm.isHover = props.option.id
                          },
                          mouseleave: function ($event) {
                            _vm.isHover = null
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("search"),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]
          },
        },
      ]),
      model: {
        value: _vm.otherServiceSelected,
        callback: function ($$v) {
          _vm.otherServiceSelected = $$v
        },
        expression: "otherServiceSelected",
      },
    },
    [
      _c("template", { slot: "noResult" }, [
        _vm._v("Keine passenden Leistungsträger"),
      ]),
      _vm._v(" "),
      _vm._v(" "),
      _c("span", { attrs: { slot: "caret" }, slot: "caret" }, [
        _c(
          "a",
          {
            staticClass: "multiselect-crud-link",
            attrs: {
              href: "/others?add",
              title: "Fügen Sie eine neue Leistungsträger hinzu",
              target: "_blank",
            },
          },
          [_c("i", { staticClass: "material-icons" }, [_vm._v("add")])]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }