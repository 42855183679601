<template>
    <popover trigger="hover" v-bind:container="false" class="is-large">
        <template v-if="mainPrices.length > 1 || (mainPrices.length === 1 && !mainPrices[0].checked) || isDifferent">
        <span
            class="circle"
            :class="{'is-orange': isDifferent, 'is-small-input': smallInput, 'is-green': !isDifferent }"
            v-on:click.prevent.stop="handleCircleClick"
        ></span>
        </template>
        <i
            class="link material-icons is-pointer"
            :class="{'is-orange': isDifferent, 'is-small-input': smallInput, 'is-green': !isDifferent }"
            v-else
            v-on:click.prevent.stop="handleCircleClick"
        >check
        </i>

        <div class="circle-line" v-if="mainPrices.length > 1" :class="{'is-orange': isDifferent, 'is-green': !isDifferent, 'is-small-input': smallInput}">

        </div>
        <template slot="popover">
            <ul class="has-text-left">
                <template v-if="mainPrices.length > 0">
                    <li class="level mb-0">
                        <span>Vorgeschlagene Preise (umgerechnet in {{priceCurrency}}): </span>
                        <strong>&Oslash; {{mainAverage | priceView}}</strong>
                    </li>
                    <li><hr class="my-sm" /></li>
                </template>

                <li v-for="(price, index) in mainPrices" v-bind:key="'main'+index">
                    {{price.date}} | {{price.description}}<br/>
                    <strong>
                        <span>{{price.price.amount | priceView}} {{price.price.currency}}</span>
                    </strong>
                </li>

                <li v-if="mainPrices.length > 0 && loadedPrices.length > 0">
                    <br />
                </li>

                <template v-if="loadedPrices.length > 0">
                    <li class="level mb-0">
                        <span>Weitere Preise: </span>
                        <strong>&Oslash; {{loadedAverage | priceView}}</strong>
                    </li>
                    <li><hr class="my-sm" /></li>
                </template>

                <li v-for="(price,index) in loadedPrices" v-bind:key="'loaded-'+index">
                    {{price.date}} | {{price.description}}<br/>
                    <strong>
                        <span>{{price.price.amount | priceView}} {{price.price.currency}}</span>
                    </strong>
                </li>
            </ul>
        </template>
    </popover>
</template>

<script>
import mean from 'lodash/mean';
import Popover from '@components/Popover';
import { priceView, fullYear } from '@utilities/functions'
import store from '@calculations/store';

export default {
    store,

    props: {
        providerPrices: {},
        value: {},
        smallInput: {default: false},
        priceCurrency: {},
    },

    data: function () {
        return {
            neededExchangeRate: null
        }
    },

    computed: {
        calculationSaved: function () {
            return this.$store.state.calculationSaved
        },

        isDifferent: function () {
            return priceView(this.mainAverage.toString()) !== this.value
        },

        mainPrices: function () {
            return this.providerPrices.mainPrices && this.providerPrices.mainPrices.length > 0 ? this.providerPrices.mainPrices : [];
        },

        loadedPrices: function () {
            return this.providerPrices.loadedPrices && this.providerPrices.loadedPrices.length > 0 ? this.providerPrices.loadedPrices : [];
        },

        loadedPriceCurrency: function() {
            return this.mainPrices.length > 0 ? this.mainPrices[0].currency : null;
        },

        exchangeRate: function () {
            if(this.priceCurrency === this.loadedPriceCurrency){
                return 1
            }
            let findRate = this.savedExchangeRates.find(rate =>
                rate.fromCurrencyCode === this.loadedPriceCurrency &&
                rate.toCurrencyCode === this.priceCurrency
            );
            if(!findRate){
                //todo: if no rate found, get default one from backend and add to savedExcxhangeRates
                return 0
            }
            return findRate.rate;
        },

        savedExchangeRates: function () {
            return this.$store.state.savedExchangeRates;
        },

        mainAverage: function() {
            return  this.mainPrices.length > 0
                ? mean(this.mainPrices.map(price => {
                    return parseFloat(price.price.amount) * this.exchangeRate
                })) : false
        },

        loadedAverage: function() {
            return this.loadedPrices.length > 0
                ? mean(this.loadedPrices.map(price => {
                    return parseFloat(price.price.amount) * this.exchangeRate
                })) : false
        },

        isValueEmpty: function () {
            return this.value === null || this.value == '' || this.value === "0" || this.value === "0.00" || this.value === "0,00"
        },

        shouldSetValue: function () {
            return this.isValueEmpty  && this.isDifferent && this.mainAverage > 0 && !this.calculationSaved
        }
    },

    methods: {
        sum(...numbers) {
            let sum = 0;

            for (let number of numbers){
                sum += parseFloat(number);
            }

            return sum;
        },

        handleCircleClick: function() {
            if (this.isDifferent) {
                this.setLoadedPrice();
            }
        },

        setLoadedPrice: function () {
            this.$emit('input', priceView(this.mainAverage.toString()))
        }
    },

    watch: {
        mainAverage: {
            immediate: true,
            handler: function () {
                if (this.shouldSetValue) {
                    this.setLoadedPrice()
                }
            }
        },

        calculationSaved: {
            handler: function (saved) {
                if (!saved && this.shouldSetValue) {
                    this.setLoadedPrice();
                }
            }
        }
    },

    components: {
        Popover
    },

    filters: {
        priceView,
        fullYear
    }
}
</script>
