var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bp-box is-info" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("main", [
        !_vm.request.hotel || _vm.editMode
          ? _c(
              "div",
              { staticClass: "is-one-row" },
              [
                _c(
                  "button",
                  {
                    class: [
                      "btn-single-toggle",
                      _vm.onlyFromOrder ? "" : "is-active",
                    ],
                    staticStyle: {
                      border: "1px solid #dddddd",
                      "margin-right": "5px",
                      "min-width": "22px",
                      "min-height": "22px",
                    },
                    attrs: {
                      title: _vm.onlyFromOrder
                        ? "Suche ist auf obigen Ort eingeschränkt"
                        : "Suche ist nicht eingeschränkt",
                    },
                    on: {
                      click: function ($event) {
                        _vm.onlyFromOrder = !_vm.onlyFromOrder
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { margin: "0" },
                      },
                      [_vm._v("flag")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("hotel-select", {
                  attrs: {
                    placeholderInfo: _vm.onlyFromOrder
                      ? _vm.localPlaceholderInfo
                      : { area: null, place: null },
                    "show-icon": false,
                    "multi-select-style": "width: 400px",
                  },
                  model: {
                    value: _vm.request.hotel,
                    callback: function ($$v) {
                      _vm.$set(_vm.request, "hotel", $$v)
                    },
                    expression: "request.hotel",
                  },
                }),
                _vm._v(" "),
                _vm.editMode
                  ? _c("agency-select", {
                      attrs: {
                        value: _vm.request.agency,
                        provider: null,
                        text: "Agentur",
                        prefill: "",
                      },
                      on: { update: (agency) => (_vm.request.agency = agency) },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "is-one-row" }, [
          _c("div", [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("import_contacts"),
            ]),
            _vm._v(" Status:\n                    "),
            _c("div", { staticClass: "select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.request.requestStatus.status,
                      expression: "request.requestStatus.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.request.requestStatus,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _vm._l(_vm.requestStatuses, function (status) {
                    return [
                      _c("option", { domProps: { value: status.key } }, [
                        _vm._v(_vm._s(status.value)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("notifications_none"),
              ]),
              _vm._v(" Termine:\n                    "),
              _vm.request.todos && _vm.showSelectList
                ? _c("multiselect", {
                    staticClass: "is-shorter",
                    staticStyle: { "min-width": "180px" },
                    attrs: {
                      searchable: false,
                      options: _vm.request.todos,
                      "custom-label": _vm.todoLabel,
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.selectedTodo,
                      callback: function ($$v) {
                        _vm.selectedTodo = $$v
                      },
                      expression: "selectedTodo",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showTodoInput
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedTodo.name,
                        expression: "selectedTodo.name",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.selectedTodo.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.selectedTodo, "name", $event.target.value)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedTodo
                ? _c("datepicker", {
                    attrs: {
                      inputClass: ["is-date"],
                      config: {
                        dateFormat: "d.m.Y H:i:S",
                        wrap: true,
                        parseDate: function (dateStr) {
                          return _vm
                            .moment(dateStr, "DD.MM.YYYY HH:mm:ss")
                            .toDate()
                        },
                        formatDate: function (dateObj) {
                          return _vm
                            .moment(dateObj)
                            .format("DD.MM.YYYY HH:mm:ss")
                        },
                        allowInput: true,
                        altInput: true,
                      },
                      placeholder: "Deadline",
                    },
                    model: {
                      value: _vm.deadline,
                      callback: function ($$v) {
                        _vm.deadline = $$v
                      },
                      expression: "deadline",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("button", { on: { click: _vm.addTodo } }, [
                _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
              ]),
              _vm._v(" "),
              _vm.request.todos.length > 1
                ? _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.deleteTodoInput(_vm.request.optionTodo)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("delete"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.request.optionNr,
                  expression: "request.optionNr",
                },
              ],
              staticClass: "input",
              attrs: { type: "text" },
              domProps: { value: _vm.request.optionNr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.request, "optionNr", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._m(2),
              _vm._v(" "),
              _c("multiselect", {
                staticClass: "is-shorter",
                staticStyle: { "min-width": "180px" },
                attrs: {
                  options: this.request.agency
                    ? this.agencyContactOptions
                    : this.providerContactOptions,
                  "track-by": "id",
                  "custom-label": (contact) => `${contact.fullName}`,
                  "to-be-copied": false,
                },
                model: {
                  value: _vm.request.requestContact,
                  callback: function ($$v) {
                    _vm.$set(_vm.request, "requestContact", $$v)
                  },
                  expression: "request.requestContact",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.showAssignedContactAddress
          ? _c("div", { staticClass: "is-last-row" }, [
              _c("div", [
                _vm._m(3),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-icon-button",
                    class: {
                      "is-active":
                        _vm.request.visibleContactDetails.includes("address"),
                    },
                    attrs: { title: "Adresse anzeigen" },
                    on: {
                      click: function ($event) {
                        return _vm.setVisibleContactDetails("address")
                      },
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("home")])]
                ),
              ]),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("address")
                ? _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("home"),
                      ]),
                      _vm._v(" Adresse\n                    "),
                      _c("multiselect", {
                        staticClass: "is-shorter",
                        staticStyle: { "min-width": "150px" },
                        attrs: {
                          options: _vm.providerAddressOptions,
                          "track-by": "id",
                          "custom-label": (address) =>
                            (address.title
                              ? address.title + ": " + address.streetAddress
                              : address.streetAddress
                            ).substring(0, 50),
                          "to-be-copied": false,
                          placeholder: "Keine Adresse",
                        },
                        model: {
                          value: _vm.request.assignedAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "assignedAddress", $$v)
                          },
                          expression: "request.assignedAddress",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("address")
                ? _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("list"),
                    ]),
                    _vm._v(" Kontaktdetails sichtbar:\n                    "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-icon-button",
                        class: {
                          "is-active":
                            _vm.request.visibleContactDetails.includes(
                              "phoneFixed"
                            ),
                        },
                        attrs: { title: "Festnetz anzeigen" },
                        on: {
                          click: function ($event) {
                            return _vm.setVisibleContactDetails("phoneFixed")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("phone"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-icon-button",
                        class: {
                          "is-active":
                            _vm.request.visibleContactDetails.includes(
                              "phoneMobile"
                            ),
                        },
                        attrs: { title: "Mobile anzeigen" },
                        on: {
                          click: function ($event) {
                            return _vm.setVisibleContactDetails("phoneMobile")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("smartphone"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "toggle-switch",
          {
            model: {
              value: _vm.hasConnectedContingent,
              callback: function ($$v) {
                _vm.hasConnectedContingent = $$v
              },
              expression: "hasConnectedContingent",
            },
          },
          [
            _c("option", { domProps: { value: true } }, [
              _c("i", { staticClass: "material-icons mr-2" }, [_vm._v("link")]),
              _vm._v(" Mit Kundenkontingent verknüpfen"),
            ]),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [
              _c("i", { staticClass: "material-icons mr-2" }, [
                _vm._v("link_off"),
              ]),
              _vm._v(" Individuelles Kontingent eingeben"),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.request.contingent
            ? _c("simple-hotel-contingent", {
                attrs: {
                  type: "hotel",
                  nrOfPersons: 2,
                  types: _vm.hotelTypes,
                  isInPackage: !!_vm.request.package,
                  hasConnectedContingent: _vm.request.hasConnectedContingent,
                },
                model: {
                  value: _vm.request.contingent.doubleRooms,
                  callback: function ($$v) {
                    _vm.$set(_vm.request.contingent, "doubleRooms", $$v)
                  },
                  expression: "request.contingent.doubleRooms",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.request.contingent
            ? _c("simple-hotel-contingent", {
                attrs: {
                  type: "hotel",
                  nrOfPersons: 1,
                  types: _vm.hotelTypes,
                  isInPackage: !!_vm.request.package,
                  hasConnectedContingent: _vm.request.hasConnectedContingent,
                },
                model: {
                  value: _vm.request.contingent.singleRooms,
                  callback: function ($$v) {
                    _vm.$set(_vm.request.contingent, "singleRooms", $$v)
                  },
                  expression: "request.contingent.singleRooms",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.request.contingent
            ? _c("hotel-contingent", {
                attrs: {
                  type: "hotel",
                  types: _vm.hotelTypes,
                  hasConnectedContingent: _vm.request.hasConnectedContingent,
                },
                model: {
                  value: _vm.request.contingent.otherRooms,
                  callback: function ($$v) {
                    _vm.$set(_vm.request.contingent, "otherRooms", $$v)
                  },
                  expression: "request.contingent.otherRooms",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("tip-tap", {
            model: {
              value: _vm.request.notes,
              callback: function ($$v) {
                _vm.$set(_vm.request, "notes", $$v)
              },
              expression: "request.notes",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.request.package
            ? _c("item-prices", {
                attrs: {
                  "is-package": true,
                  isWithoutPrice: false,
                  provider: _vm.provider,
                },
                model: {
                  value: _vm.request.package.itemPrices,
                  callback: function ($$v) {
                    _vm.$set(_vm.request.package, "itemPrices", $$v)
                  },
                  expression: "request.package.itemPrices",
                },
              })
            : _c("item-prices", {
                attrs: { isWithoutPrice: false, provider: _vm.provider },
                model: {
                  value: _vm.request.itemPrices,
                  callback: function ($$v) {
                    _vm.$set(_vm.request, "itemPrices", $$v)
                  },
                  expression: "request.itemPrices",
                },
              }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("info")]),
      _vm._v(" Informationen zur Anfrage"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [
        _vm._v("format_list_numbered"),
      ]),
      _vm._v(" Reservierungs-Nr:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("contact_phone")]),
      _vm._v(" Ansprechpartner:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("list")]),
      _vm._v(" Für Kunde sichtbar:"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "explanation" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("check_circle")]),
      _vm._v(" = bestätigtes Kontingent\n        "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
      _vm._v(" = angefragtes Kontingent\n        "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("person_add")]),
      _vm._v(" = Extrazimmer für Busfahrer, Reiseleiter, etc.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }