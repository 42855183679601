<template>
    <div class="stars" :class="sizeClass" v-if="stars !== null">
        <i v-for="n in stars" v-bind:key="n" class="material-icons active">star</i>
        <span v-if="stars === 0">ohne Klassifizierung</span>
    </div>
</template>

<script>
export default {
    props: ['count', 'size'],

    computed: {
        stars: function () {
            return Number(this.count);
        },

        sizeClass: function () {
            if (!!this.size) {
                return `stars-${this.size}`;
            }
        }
    }
}
</script>
