<template>
    <modal-form
        editTitle="Menü bearbeiten"
        createTitle="Neues Menü hinzufügen"
    >
        <loading v-if="isLoading"></loading>
        <table v-if="!isLoading">
            <tr>
                <td colspan="2">
                    <h2>Menü</h2>
                </td>
            </tr>
            <tr>
                <th><label for="title" class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="form.type">
                        <option v-for="(name, value) in menuTypes" v-bind:key="value" v-bind:value="value">{{name}}</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr>
                <th class="is-top">
                    <label class="label">Zeitraum:</label>
                </th>

                <td>
                    <!-- Time periods -->
                    <div class="level-left">
                        <div class="flatpickr-combined level-item">
                            <input-date v-model="form.startAt" class="is-small"  />
                            <span>-</span>
                            <input-date v-model="form.endAt" class="is-small"  />
                        </div>
                    </div>
                </td>
            </tr>

            <tr>
                <th><label for="title" class="label">Erfassung:</label></th>
                <td>
                    <div class="columns">
                        <div class="column is-one-third" v-for="requestType in optionsArray.menuRequestTypes" >
                            <label class="checkbox">
                                <input type="checkbox" v-model="form.requestTypes" v-bind:value="requestType.key" v-bind:key="requestType.key">
                                {{ requestType.value}}
                            </label>
                        </div>
                    </div>

                    <!--                    <toggle-switch v-model="form.priceType">
                                            <template v-for="(name, value) in menuPriceTypes">
                                                <option v-if="isHotel || 'service' === value" v-bind:key="value" v-bind:value="value">{{name}}</option>
                                            </template>
                                        </toggle-switch>-->
                </td>
            </tr>

            <template v-if="isMenu">
                <tr>
                    <th><label for="title" class="label">Interner Titel:</label></th>
                    <td>
                        <div class="control">
                            <input type="text" id="title" class="input" v-model="form.title" placeholder="Dorade mit Reis (2022)">
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="is-top"><label class="label">Menü:</label></th>
                    <td>
                        <div class="control">
                            <!-- <textarea rows="5" v-model="form.content" @input="formatValue" wrap="hard" maxlength="100"></textarea>-->
                            <tip-tap
                                :max-characters="settings.crud.menus.tipTap.maxCharacters"
                                :max-lines="settings.crud.menus.tipTap.maxLines"
                                v-model="form.content"
                                isNotAlone
                                :menuItems="['fullscreen', 'bold', 'italic', 'underline', 'bullet_list', 'ordered_list', 'color', 'undo']"
                            />
                        </div>
                    </td>
                </tr>

            </template>






            <template v-if="form.requestTypes && form.requestTypes.includes('other')">
                <tr>
                    <td colspan="2">
                        <h2>Preis für Zusatzleistung</h2>
                    </td>
                </tr>



                <tr v-if="form.otherService">
                    <th><label for="otherServiceType" class="label">Leistungsart:</label></th>
                    <td>
                        <div class="control">
                            <async-select
                                class="is-shorter"
                                api="other_service_types"
                                v-model="form.otherService.otherServiceType"
                                :params="{_groups: ['area_read', 'place_read', 'destination_read'], category: 'restaurant'}"
                                :custom-label="otherServiceTypeLabel"
                                crudLinkTitle = "Füge einen neue Leistung hinzu"
                                @input="setPrice"
                            />
                        </div>
                    </td>
                </tr>

                <tr v-if="form.price" >
                    <th class="is-top">
                        <label class="label">Preis:</label>
                    </th>

                    <td>
                        <!-- Time periods -->
                        <div class="level-left">
                            <div class="level-item">
                                <multiselect
                                    v-model="form.price.currency"
                                    style="width: 80px"
                                    :options="currencies"
                                    :allow-empty="false"
                                    placeholder=""
                                    class="level-item"
                                />
                                <input-price v-model="form.price.amount" class="is-medium level-item" placeholder="0,00" :prefix="form.price.currency"/>
                            </div>

                        </div>
                    </td>
                </tr>
            </template>

            <template v-if="form.requestTypes && form.requestTypes.includes('hotel')">
                <tr >
                    <td colspan="2">
                        <h2>Eventueller Zuschlag bei HP</h2>
                    </td>
                </tr>

                <tr>
                    <th class="is-top">
                        <label class="label">HP-Zuschlag:</label>
                    </th>

                    <td>
                        <!-- Time periods -->
                        <div class="level-left">
                            <div class="level-item">
                                <multiselect
                                    v-model="form.supplementPrice.currency"
                                    style="width: 80px"
                                    :options="currencies"
                                    :allow-empty="false"
                                    placeholder=""
                                    class="level-item"
                                />
                                <input-price v-model="form.supplementPrice.amount" class="is-medium level-item" placeholder="0,00" :prefix="form.supplementPrice.currency"/>
                            </div>

                        </div>
                    </td>
                </tr>

            </template>

            <tr v-if="isMenu">
                <td colspan="2">
                    <h2>Übersetzungen</h2>
                </td>
            </tr>

            <translations-field v-if="isMenu" v-model="locale" :translations="form.translations" :translation-options="translationOptions" v-on:changeTranslation="changeTranslation"></translations-field>



        </table>
    </modal-form>

</template>



<script>
import {InputDate, InputPrice, TipTap, ToggleSwitch} from '@components/form';
import TabForm from "@components/CRUD/Item/Tabs/form";
import {translationsField, translationsMixin} from "@utilities/mixins";
import Mixin from "@components/CRUD/Item/Tabs/tab";
import {notifyError, notifySuccess} from "@components/Notification";
import {edit, getAxios, post} from "@api";
import Loading from "@components/Loading.vue";
import ModalForm from "@components/ModalForm.vue";
import {settings} from "@clientSpecific/utilities/settings";
import {prepareForBackend} from "@utilities/functions";
import moment from "moment";
import toNumber from "lodash/toNumber";

export default {
    mixins: [TabForm, translationsMixin, Mixin],

    props: {
        provider: {
            type: Object
        },
        optionsArray : {
            type: Object
        },
    },

    data () {
        return {
            settings: settings
        }
    },

    computed: {
        isMenu: function () {
            return this.form.type === 'menu'
        },
        isHotel () {
            return this.provider && this.provider.providerType === 'hotel';
        },
        currencies: function () {
            return this.optionsArray.potentialCurrencies ? this.optionsArray.potentialCurrencies : [];
        },
        menuPriceTypes: function () {
            return this.optionsArray.menuPriceTypes ? this.optionsArray.menuPriceTypes : [];
        },
        menuTypes: function () {
            return this.optionsArray.menuTypes ? this.optionsArray.menuTypes : [];
        },
        formData: function () {
            return prepareForBackend(this.form)
        }
    },
    methods: {
        loadData () {
            return new Promise((resolve, reject) => {
                if(this.form.id && this.form.otherService === 'undefined') {
                    getAxios('menus/' + this.form.id, {
                        _groups: []
                    }).then(response => {
                        resolve(response.data)
                    })
                } else {
                    resolve(this.form)
                }
            });
        },

        getDefaultOtherServiceType () {
            getAxios('other_service_types', {
                'id': settings.crud.menus.defaultOtherServiceTypeId,
            }).then(response => {
                if(response.data.length > 0) {
                    this.form.otherService.otherServiceType = response.data[0];
                    this.setPrice();
                }
            })
        },

        setPrice () {
            if(this.form.otherService.otherServiceType) {
                getAxios('other_services', {
                    'otherServiceType.id': this.form.otherService.otherServiceType.id,
                    'provider.id': this.provider.id,
                    'priceGroups.timePeriods.startAt[before]': this.form.endAt,
                    'priceGroups.timePeriods.endAt[after]': this.form.startAt,
                    _groups: ['provider_price_group:provider_price', 'provider_price', 'money_read'],
                }).then(response => {
                    let data = response.data.sort((a,b) => {
                        if(a.subtitle && a.subtitle !== 'Kalkulationspreis') {
                            return 1;
                        }
                        if(b.subTitle && b.subtitle !== 'Kalkulationspreis') {
                            return 1;
                        }
                    });

                    if(data[0] && data[0].priceGroups && data[0].priceGroups[0] && data[0].priceGroups[0].providerPrices[0] && data[0].priceGroups[0].providerPrices[0].price) {
                        this.form.price = data[0].priceGroups[0].providerPrices[0].price
                    } else {
                        this.form.price = {
                            amount: null,
                            currency: this.provider.mainCurrency
                        }
                    }


                })
            }
        },

        otherServiceTypeLabel: function (otherServiceType) {
            const location = this.locationName(otherServiceType);
            return `${otherServiceType.name} ${!!location ? `(${location})` : ''}`;
        },

        locationName: function (otherServiceType) {
            const { area, destination, place } = otherServiceType;
            return !!area ? area.name : !!place ? place.name : !!destination ? destination.name : '';
        },

        update: function () {
            let data = this.prepareForm(JSON.parse(JSON.stringify(this.form)));
            let id = this.form.id;
            return new Promise((resolve, reject) => {
                edit('menus/' + id, data,
                    {
                        _groups : ['menu:provider_other_service', 'other_service_write', 'other_service_type_read', 'translations', 'menu:provider', 'provider_other_service:menu'],
                    },
                    {
                        headers:{
                            'X-LOCALE': this.locale
                        }
                    }).then(response => {
                    if(response.data.otherService) {
                        this.form.otherService.id = response.data.otherService.id;
                    }
                    resolve(response);
                });
            })
        },

        create: function () {

            let data = this.prepareForm(JSON.parse(JSON.stringify(this.form)));

            return new Promise((resolve, reject) => {
                post('menus', data, {
                    _groups : ['menu:provider_other_service', 'other_service_write', 'other_service_type_read', 'translations', 'menu:provider', 'provider_other_service:menu'],
                }).then(response => {
                    if(response.data.otherService) {
                        this.form.otherService.id = response.data.otherService.id;
                    }
                    resolve(response);
                });
            })
        },

        openCallback: function() {
            this.loadData().then(data => {
                this.form = data;
                if(!this.form.id) {
                    this.form.requestTypes = this.provider.providerType === 'hotel' ? ['hotel'] : ['other']
                }
            })
            if(this.provider) {
                this.form.price.currency = this.provider.mainCurrency;
                this.form.supplementPrice.currency = this.provider.mainCurrency;
            }

            this.locale = this.defaultTranslation;

            if(!this.form.translations || Object.keys(this.form.translations).length === 0){
                this.form.translations = Object.assign({}, {   [this.defaultTranslation] : {}     });
                //this.form.translations.push({   [this.defaultTranslation] : {}     })
            }
        },

        prepareForm (data) {
            if (data.startAt) {
                data.startAt = data.startAt && data.startAt.length < 19 ? data.startAt + ' 00:00:00' : data.startAt;
            }
            if (data.endAt) {
                data.endAt = data.endAt && data.endAt.length < 19 ? data.endAt + ' 00:00:00' : data.endAt;
            }
            data.content = this.isMenu ? data.content : '';
            if(data.requestTypes.includes('other')) {
                if(data.otherService && data.otherService.otherServiceType) {
                    data.otherService = {
                        useNestedData: true,
                        ...data.otherService && {id: data.otherService.id},
                        otherServiceType: '/api/other_service_types/' + data.otherService.otherServiceType.id,
                        provider: this.provider  ? '/api/providers/' + this.provider.id : null,
                        subtitle: data.title,
                    }
                } else {
                    data.otherService = null;
                }
            } else {
                data.otherService = null;
            }
            if(this.provider) {
                data.provider = '/api/providers/' + this.provider.id;
            }
            if(data.price && !toNumber(data.price.amount)) {
                data.price.amount = null;
            }
            if(data.supplementPrice && !toNumber(data.supplementPrice.amount)) {
                data.supplementPrice.amount = null;
            }

            return data;
        },
        submitForm: function () {

            if (this.form.id) {
                return new Promise((resolve, reject) => {
                    edit('menus/' + this.form.id, this.form,
                        {
                            _groups: ['menu:provider_other_service', 'other_service_write', 'other_service_type_read', 'translations', 'menu'],
                        },
                        {
                            headers: {
                                'X-LOCALE': this.locale
                            }
                        }).then(response => {
                        if (response.data.otherService) {
                            this.form.otherService.id = response.data.otherService.id;
                        }
                        resolve(response);
                    }, error => {
                        reject(error);
                    });
                });
            } else {
                return new Promise((resolve, reject) => {
                    post('menus', this.form, {
                        _groups : ['menu:provider_other_service', 'other_service_write', 'other_service_type_read', 'translations'],
                    }).then(response => {
                        if(response.data.otherService) {
                            this.form.otherService.id = response.data.otherService.id;
                        }
                        resolve(response);
                    }, error => {
                        reject(error);
                    });
                })
            }

        },

        handleSubmit: function () {
            if(this.form.requestTypes.includes('other') && !this.form.otherService.otherServiceType) {
                notifyError('Bitte Zusatzleistung angeben');
            } else {
                this.prepareForm(this.form);
                this.submitForm().then(res => {
                    this.visible = false;
                    this.form.startAt = null;
                    this.form.endAt = null;
                    notifySuccess('Menü erfolgreich hinzugefügt!');
                    this.$emit('updateMenus');
                }, error => notifyError('Server Error! Das Menü konnte nicht hinzugefügt werden') );
            }
        },
    },

    watch: {
        'form.requestTypes': function() {
            if(this.form.requestTypes && this.form.requestTypes.includes('other') && !this.form.id){
                this.getDefaultOtherServiceType();
            }
        }
    },

    form: {
        translations: {},
        type: 'menu',
        otherService: {
            otherServiceType: null
        },
        provider: null,
        agency: null,
        title: '',
        content: '',
        startAt: moment().startOf('year').format('DD.MM.YYYY'),
        endAt: moment().endOf('year').format('DD.MM.YYYY'),
        price: {
            amount: null,
            currency: 'EUR'
        },
        supplementPrice: {
            amount: null,
            currency: 'EUR'
        },
        requestTypes: []
    },
    property: 'menus',

    components: {
        Loading,
        TipTap,
        translationsField,
        ToggleSwitch,
        InputDate,
        InputPrice,
        ModalForm
    }
}
</script>
