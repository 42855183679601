var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      attrs: {
        editTitle: "Menü bearbeiten",
        createTitle: "Neues Menü hinzufügen",
      },
    },
    [
      _vm.isLoading ? _c("loading") : _vm._e(),
      _vm._v(" "),
      !_vm.isLoading
        ? _c(
            "table",
            [
              _c("tr", [
                _c("td", { attrs: { colspan: "2" } }, [
                  _c("h2", [_vm._v("Menü")]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "title" } },
                    [_vm._v("Art:")]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.form.type,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "type", $$v)
                          },
                          expression: "form.type",
                        },
                      },
                      _vm._l(_vm.menuTypes, function (name, value) {
                        return _c(
                          "option",
                          { key: value, domProps: { value: value } },
                          [_vm._v(_vm._s(name))]
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", { staticClass: "is-top" }, [
                  _c("label", { staticClass: "label" }, [_vm._v("Zeitraum:")]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "level-left" }, [
                    _c(
                      "div",
                      { staticClass: "flatpickr-combined level-item" },
                      [
                        _c("input-date", {
                          staticClass: "is-small",
                          model: {
                            value: _vm.form.startAt,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "startAt", $$v)
                            },
                            expression: "form.startAt",
                          },
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v("-")]),
                        _vm._v(" "),
                        _c("input-date", {
                          staticClass: "is-small",
                          model: {
                            value: _vm.form.endAt,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "endAt", $$v)
                            },
                            expression: "form.endAt",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [
                  _c(
                    "label",
                    { staticClass: "label", attrs: { for: "title" } },
                    [_vm._v("Erfassung:")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "columns" },
                    _vm._l(
                      _vm.optionsArray.menuRequestTypes,
                      function (requestType) {
                        return _c(
                          "div",
                          { staticClass: "column is-one-third" },
                          [
                            _c("label", { staticClass: "checkbox" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.requestTypes,
                                    expression: "form.requestTypes",
                                  },
                                ],
                                key: requestType.key,
                                attrs: { type: "checkbox" },
                                domProps: {
                                  value: requestType.key,
                                  checked: Array.isArray(_vm.form.requestTypes)
                                    ? _vm._i(
                                        _vm.form.requestTypes,
                                        requestType.key
                                      ) > -1
                                    : _vm.form.requestTypes,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.form.requestTypes,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = requestType.key,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.form,
                                            "requestTypes",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.form,
                                            "requestTypes",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(_vm.form, "requestTypes", $$c)
                                    }
                                  },
                                },
                              }),
                              _vm._v(
                                "\n                            " +
                                  _vm._s(requestType.value) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.isMenu
                ? [
                    _c("tr", [
                      _c("th", [
                        _c(
                          "label",
                          { staticClass: "label", attrs: { for: "title" } },
                          [_vm._v("Interner Titel:")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "control" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.title,
                                expression: "form.title",
                              },
                            ],
                            staticClass: "input",
                            attrs: {
                              type: "text",
                              id: "title",
                              placeholder: "Dorade mit Reis (2022)",
                            },
                            domProps: { value: _vm.form.title },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(_vm.form, "title", $event.target.value)
                              },
                            },
                          }),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { staticClass: "is-top" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Menü:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c(
                          "div",
                          { staticClass: "control" },
                          [
                            _c("tip-tap", {
                              attrs: {
                                "max-characters":
                                  _vm.settings.crud.menus.tipTap.maxCharacters,
                                "max-lines":
                                  _vm.settings.crud.menus.tipTap.maxLines,
                                isNotAlone: "",
                                menuItems: [
                                  "fullscreen",
                                  "bold",
                                  "italic",
                                  "underline",
                                  "bullet_list",
                                  "ordered_list",
                                  "color",
                                  "undo",
                                ],
                              },
                              model: {
                                value: _vm.form.content,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "content", $$v)
                                },
                                expression: "form.content",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.form.requestTypes && _vm.form.requestTypes.includes("other")
                ? [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("h2", [_vm._v("Preis für Zusatzleistung")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.form.otherService
                      ? _c("tr", [
                          _c("th", [
                            _c(
                              "label",
                              {
                                staticClass: "label",
                                attrs: { for: "otherServiceType" },
                              },
                              [_vm._v("Leistungsart:")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              { staticClass: "control" },
                              [
                                _c("async-select", {
                                  staticClass: "is-shorter",
                                  attrs: {
                                    api: "other_service_types",
                                    params: {
                                      _groups: [
                                        "area_read",
                                        "place_read",
                                        "destination_read",
                                      ],
                                      category: "restaurant",
                                    },
                                    "custom-label": _vm.otherServiceTypeLabel,
                                    crudLinkTitle:
                                      "Füge einen neue Leistung hinzu",
                                  },
                                  on: { input: _vm.setPrice },
                                  model: {
                                    value:
                                      _vm.form.otherService.otherServiceType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.otherService,
                                        "otherServiceType",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.otherService.otherServiceType",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.price
                      ? _c("tr", [
                          _c("th", { staticClass: "is-top" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v("Preis:"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c("div", { staticClass: "level-left" }, [
                              _c(
                                "div",
                                { staticClass: "level-item" },
                                [
                                  _c("multiselect", {
                                    staticClass: "level-item",
                                    staticStyle: { width: "80px" },
                                    attrs: {
                                      options: _vm.currencies,
                                      "allow-empty": false,
                                      placeholder: "",
                                    },
                                    model: {
                                      value: _vm.form.price.currency,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.price,
                                          "currency",
                                          $$v
                                        )
                                      },
                                      expression: "form.price.currency",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("input-price", {
                                    staticClass: "is-medium level-item",
                                    attrs: {
                                      placeholder: "0,00",
                                      prefix: _vm.form.price.currency,
                                    },
                                    model: {
                                      value: _vm.form.price.amount,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form.price, "amount", $$v)
                                      },
                                      expression: "form.price.amount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.form.requestTypes && _vm.form.requestTypes.includes("hotel")
                ? [
                    _c("tr", [
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("h2", [_vm._v("Eventueller Zuschlag bei HP")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { staticClass: "is-top" }, [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("HP-Zuschlag:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("div", { staticClass: "level-left" }, [
                          _c(
                            "div",
                            { staticClass: "level-item" },
                            [
                              _c("multiselect", {
                                staticClass: "level-item",
                                staticStyle: { width: "80px" },
                                attrs: {
                                  options: _vm.currencies,
                                  "allow-empty": false,
                                  placeholder: "",
                                },
                                model: {
                                  value: _vm.form.supplementPrice.currency,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.supplementPrice,
                                      "currency",
                                      $$v
                                    )
                                  },
                                  expression: "form.supplementPrice.currency",
                                },
                              }),
                              _vm._v(" "),
                              _c("input-price", {
                                staticClass: "is-medium level-item",
                                attrs: {
                                  placeholder: "0,00",
                                  prefix: _vm.form.supplementPrice.currency,
                                },
                                model: {
                                  value: _vm.form.supplementPrice.amount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form.supplementPrice,
                                      "amount",
                                      $$v
                                    )
                                  },
                                  expression: "form.supplementPrice.amount",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.isMenu
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [
                      _c("h2", [_vm._v("Übersetzungen")]),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isMenu
                ? _c("translations-field", {
                    attrs: {
                      translations: _vm.form.translations,
                      "translation-options": _vm.translationOptions,
                    },
                    on: { changeTranslation: _vm.changeTranslation },
                    model: {
                      value: _vm.locale,
                      callback: function ($$v) {
                        _vm.locale = $$v
                      },
                      expression: "locale",
                    },
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }