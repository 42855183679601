var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "bp-box is-info" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("main", [
        !_vm.request.otherService || _vm.editMode
          ? _c(
              "div",
              { staticClass: "is-one-row" },
              [
                _c(
                  "div",
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn-single-toggle",
                        class: [_vm.onlyAssignedProviders ? "is-active" : ""],
                        staticStyle: {
                          border: "1px solid #dddddd",
                          "margin-right": "5px",
                          "min-width": "22px",
                          "min-height": "22px",
                        },
                        attrs: {
                          title: _vm.onlyAssignedProviders
                            ? "Klicken, um auch nicht zugewiesene Leistungsträger zu finden"
                            : "Klicken, um nur zugewiesene Leistungsträger anzuzeigen",
                        },
                        on: {
                          click: function ($event) {
                            _vm.onlyAssignedProviders =
                              !_vm.onlyAssignedProviders
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: { margin: "0" },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.onlyAssignedProviders ? "lock" : "lock_open"
                              )
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(
                      "\n                    Leistungsträger:\n                    "
                    ),
                    _c("async-select", {
                      staticClass: "is-shorter",
                      staticStyle: { width: "300px" },
                      attrs: {
                        api: "providers",
                        placeholder: "No-Name",
                        params: {
                          ...(_vm.onlyAssignedProviders && {
                            "otherServices.otherServiceType.id":
                              _vm.placeholderInfo.otherServiceType.id,
                            "providerWithLocation.place.id":
                              _vm.placeholderInfo.place &&
                              _vm.placeholderInfo.place.id,
                            "providerWithLocation.place.area.id":
                              _vm.placeholderInfo.area &&
                              _vm.placeholderInfo.area.id,
                            "providerWithLocation.place.destination.id":
                              _vm.placeholderInfo.destination &&
                              _vm.placeholderInfo.destination.id,
                          }),
                          _groups: ["provider_read"],
                        },
                      },
                      on: { input: _vm.handleProviderAgencyChange },
                      model: {
                        value: _vm.selectedProvider,
                        callback: function ($$v) {
                          _vm.selectedProvider = $$v
                        },
                        expression: "selectedProvider",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.editMode
                  ? _c("agency-select", {
                      attrs: {
                        "multiselect-class": "is-shorter",
                        provider: null,
                        params: "&_groups[]=other_service_read",
                        prefill: true,
                        text: "Agentur",
                      },
                      on: { input: _vm.handleProviderAgencyChange },
                      model: {
                        value: _vm.selectedAgency,
                        callback: function ($$v) {
                          _vm.selectedAgency = $$v
                        },
                        expression: "selectedAgency",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showOtherServiceSubTitleSelect
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n                    Zusatztitel:\n                    "
                        ),
                        _c("other-service-select", {
                          staticClass: "has-margin-left is-shorter",
                          attrs: {
                            "sub-title-only": true,
                            "agency-exist": !!_vm.selectedAgency,
                            agencyID: _vm.selectedAgency
                              ? _vm.selectedAgency.id
                              : null,
                            providerID: _vm.selectedProvider
                              ? _vm.selectedProvider.id
                              : null,
                            otherServiceTypeID:
                              _vm.placeholderInfo.otherServiceType.id,
                          },
                          model: {
                            value: _vm.request.otherService,
                            callback: function ($$v) {
                              _vm.$set(_vm.request, "otherService", $$v)
                            },
                            expression: "request.otherService",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "is-one-row" }, [
          _c("div", [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("import_contacts"),
            ]),
            _vm._v(" Status:\n                    "),
            _c("div", { staticClass: "select" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.request.requestStatus.status,
                      expression: "request.requestStatus.status",
                    },
                  ],
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.request.requestStatus,
                        "status",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _vm._l(_vm.requestStatuses, function (status) {
                    return [
                      _c("option", { domProps: { value: status.key } }, [
                        _vm._v(_vm._s(status.value)),
                      ]),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("notifications_none"),
              ]),
              _vm._v(" Termine:\n                  "),
              _vm.request.todos && _vm.showSelectList
                ? _c("multiselect", {
                    staticClass: "is-shorter",
                    staticStyle: { "min-width": "180px" },
                    attrs: {
                      searchable: false,
                      options: _vm.request.todos,
                      "custom-label": _vm.todoLabel,
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.selectedTodo,
                      callback: function ($$v) {
                        _vm.selectedTodo = $$v
                      },
                      expression: "selectedTodo",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showTodoInput
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedTodo.name,
                        expression: "selectedTodo.name",
                      },
                    ],
                    staticClass: "input",
                    attrs: { type: "text" },
                    domProps: { value: _vm.selectedTodo.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.selectedTodo, "name", $event.target.value)
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedTodo
                ? _c("datepicker", {
                    attrs: {
                      inputClass: ["is-date"],
                      config: {
                        dateFormat: "d.m.Y H:i:S",
                        wrap: true,
                        parseDate: function (dateStr) {
                          return _vm
                            .moment(dateStr, "DD.MM.YYYY HH:mm:ss")
                            .toDate()
                        },
                        formatDate: function (dateObj) {
                          return _vm
                            .moment(dateObj)
                            .format("DD.MM.YYYY HH:mm:ss")
                        },
                        allowInput: true,
                        altInput: true,
                      },
                      placeholder: "Deadline",
                    },
                    model: {
                      value: _vm.deadline,
                      callback: function ($$v) {
                        _vm.deadline = $$v
                      },
                      expression: "deadline",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("button", { on: { click: _vm.addTodo } }, [
                _c("i", { staticClass: "material-icons" }, [_vm._v("add")]),
              ]),
              _vm._v(" "),
              _vm.request.todos.length > 1
                ? _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.deleteTodoInput(_vm.request.optionTodo)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("delete"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c("i", { staticClass: "material-icons" }, [
              _vm._v("format_list_numbered"),
            ]),
            _vm._v(" Reservierungs-Nr:\n                    "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.request.optionNr,
                  expression: "request.optionNr",
                },
              ],
              staticClass: "input",
              attrs: { type: "text" },
              domProps: { value: _vm.request.optionNr },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.request, "optionNr", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("contact_phone"),
              ]),
              _vm._v(" Ansprechpartner:\n                    "),
              _c("multiselect", {
                staticClass: "is-shorter",
                staticStyle: { "min-width": "180px" },
                attrs: {
                  options: this.request.agency
                    ? this.agencyContactOptions
                    : this.providerContactOptions,
                  "track-by": "id",
                  "custom-label": (contact) => `${contact.fullName}`,
                  "to-be-copied": false,
                },
                model: {
                  value: _vm.request.requestContact,
                  callback: function ($$v) {
                    _vm.$set(_vm.request, "requestContact", $$v)
                  },
                  expression: "request.requestContact",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _vm.showAssignedContactAddress
          ? _c("div", { staticClass: "is-last-row" }, [
              _c("div", [
                _c("i", { staticClass: "material-icons" }, [_vm._v("list")]),
                _vm._v(" Für Kunde sichtbar:\n                    "),
                _c(
                  "button",
                  {
                    staticClass: "button is-icon-button",
                    class: {
                      "is-active":
                        _vm.request.visibleContactDetails.includes("address"),
                    },
                    attrs: { title: "Adresse anzeigen" },
                    on: {
                      click: function ($event) {
                        return _vm.setVisibleContactDetails("address")
                      },
                    },
                  },
                  [_c("i", { staticClass: "material-icons" }, [_vm._v("home")])]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-icon-button",
                    class: {
                      "is-active":
                        _vm.request.visibleContactDetails.includes("contact"),
                    },
                    attrs: { title: "Speziellen Kontakt anzeigen" },
                    on: {
                      click: function ($event) {
                        return _vm.setVisibleContactDetails("contact")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("contact_phone"),
                    ]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("address")
                ? _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("home"),
                      ]),
                      _vm._v(" Adresse\n                    "),
                      _c("multiselect", {
                        staticClass: "is-shorter",
                        staticStyle: { "min-width": "150px" },
                        attrs: {
                          options: _vm.providerAddressOptions,
                          "track-by": "id",
                          "custom-label": (address) =>
                            (address.title
                              ? address.title + ": " + address.streetAddress
                              : address.streetAddress
                            ).substring(0, 50),
                          "to-be-copied": false,
                          placeholder: "Keine Adresse",
                        },
                        model: {
                          value: _vm.request.assignedAddress,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "assignedAddress", $$v)
                          },
                          expression: "request.assignedAddress",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("contact")
                ? _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("contact_phone"),
                      ]),
                      _vm._v(
                        "\n                        Kontakt:\n                    "
                      ),
                      _c("multiselect", {
                        staticClass: "is-shorter",
                        staticStyle: { "min-width": "180px" },
                        attrs: {
                          options: _vm.providerContactOptions
                            .concat(_vm.agencyContactOptions)
                            .filter((item) => item.role !== "general_contact"),
                          "track-by": "id",
                          "custom-label": (contact) =>
                            contact.fullName
                              ? `${contact.fullName}`
                              : "Allgemein",
                          "to-be-copied": false,
                        },
                        model: {
                          value: _vm.request.assignedContact,
                          callback: function ($$v) {
                            _vm.$set(_vm.request, "assignedContact", $$v)
                          },
                          expression: "request.assignedContact",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.request.visibleContactDetails.includes("address") ||
              _vm.request.visibleContactDetails.includes("contact")
                ? _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("list"),
                    ]),
                    _vm._v(" Kontaktdetails sichtbar:\n                    "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-icon-button",
                        class: {
                          "is-active":
                            _vm.request.visibleContactDetails.includes(
                              "phoneFixed"
                            ),
                        },
                        attrs: { title: "Festnetz anzeigen" },
                        on: {
                          click: function ($event) {
                            return _vm.setVisibleContactDetails("phoneFixed")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("phone"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-icon-button",
                        class: {
                          "is-active":
                            _vm.request.visibleContactDetails.includes(
                              "phoneMobile"
                            ),
                        },
                        attrs: { title: "Mobile anzeigen" },
                        on: {
                          click: function ($event) {
                            return _vm.setVisibleContactDetails("phoneMobile")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("smartphone"),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "toggle-switch",
          {
            model: {
              value: _vm.hasConnectedContingent,
              callback: function ($$v) {
                _vm.hasConnectedContingent = $$v
              },
              expression: "hasConnectedContingent",
            },
          },
          [
            _c("option", { domProps: { value: true } }, [
              _c("i", { staticClass: "material-icons mr-2" }, [_vm._v("link")]),
              _vm._v(" Mit Kundenkontingent verknüpfen"),
            ]),
            _vm._v(" "),
            _c("option", { domProps: { value: false } }, [
              _c("i", { staticClass: "material-icons mr-2" }, [
                _vm._v("link_off"),
              ]),
              _vm._v(" Individuelles Kontingent eingeben"),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "bp-box is-tickets" }, [
          _c("header", [
            _c(
              "div",
              [
                _c("other-service-icons", {
                  attrs: { type: _vm.request.otherServiceType.category },
                }),
                _vm._v(" Zusatzleistung\n                    "),
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _c("div", [
              !_vm.request.package
                ? _c(
                    "button",
                    {
                      attrs: { title: "Extrabuchung für Reisebegleitung" },
                      on: { click: _vm.addExtra },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("person_add"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("main", [
            _c("div", [
              _vm._m(2),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.request.contingent.reservedAmount,
                      expression: "request.contingent.reservedAmount",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", disabled: _vm.hasConnectedContingent },
                  domProps: { value: _vm.request.contingent.reservedAmount },
                  on: {
                    focus: function ($event) {
                      return $event.target.select()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.request.contingent,
                        "reservedAmount",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.request.contingent.askedAmount,
                      expression: "request.contingent.askedAmount",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input",
                  attrs: { type: "text", disabled: _vm.hasConnectedContingent },
                  domProps: { value: _vm.request.contingent.askedAmount },
                  on: {
                    focus: function ($event) {
                      return $event.target.select()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.request.contingent,
                        "askedAmount",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "button",
                    attrs: { title: "Copy value" },
                    on: { click: _vm.copContingent },
                  },
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check_circle"),
                    ]),
                    _vm._v(" "),
                    _c("i", {
                      staticClass: "fa fa-long-arrow-left",
                      attrs: { "aria-hidden": "true" },
                    }),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("help"),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.hasExtra && !_vm.request.package
              ? _c("div", { staticClass: "extra-people" }, [
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("person_add"),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.request.contingent.extraDescription,
                          expression: "request.contingent.extraDescription",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: {
                        value: _vm.request.contingent.extraDescription,
                      },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.request.contingent,
                            "extraDescription",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-delete",
                        attrs: { title: "Löschen", tabindex: "-1" },
                        on: { click: _vm.deleteExtra },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("delete"),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.request.contingent.extraReservedAmount,
                            expression:
                              "request.contingent.extraReservedAmount",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.request.contingent.extraReservedAmount,
                        },
                        on: {
                          focus: function ($event) {
                            return $event.target.select()
                          },
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.request.contingent,
                              "extraReservedAmount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "control" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.request.contingent.extraAskedAmount,
                            expression: "request.contingent.extraAskedAmount",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.request.contingent.extraAskedAmount,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.request.contingent,
                              "extraAskedAmount",
                              _vm._n($event.target.value)
                            )
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "button",
                        attrs: { title: "Copy value" },
                        on: { click: _vm.copyExtraContingent },
                      },
                      [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("check_circle"),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "fa fa-long-arrow-left",
                          attrs: { "aria-hidden": "true" },
                        }),
                        _vm._v(" "),
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("help"),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "columns" }, [
      _c(
        "div",
        { staticClass: "column is-one-third" },
        [
          _c("tip-tap", {
            model: {
              value: _vm.request.notes,
              callback: function ($$v) {
                _vm.$set(_vm.request, "notes", $$v)
              },
              expression: "request.notes",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "column" },
        [
          _vm.request.package
            ? _c("item-prices", {
                attrs: {
                  "is-package": true,
                  isWithoutPrice: false,
                  provider: _vm.provider,
                },
                model: {
                  value: _vm.request.package.itemPrices,
                  callback: function ($$v) {
                    _vm.$set(_vm.request.package, "itemPrices", $$v)
                  },
                  expression: "request.package.itemPrices",
                },
              })
            : _c("item-prices", {
                attrs: { isWithoutPrice: false, provider: _vm.provider },
                model: {
                  value: _vm.request.itemPrices,
                  callback: function ($$v) {
                    _vm.$set(_vm.request, "itemPrices", $$v)
                  },
                  expression: "request.itemPrices",
                },
              }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _vm._m(3),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("header", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("info")]),
      _vm._v(" Informationen zur Anfrage"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("check_circle")]),
      _vm._v(" "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "material-icons" }, [_vm._v("person")]),
      _vm._v(" Teilnehmer\n                        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "explanation" }, [
      _c("i", { staticClass: "material-icons" }, [_vm._v("check_circle")]),
      _vm._v(" = bestätigtes Kontingent\n        "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("help")]),
      _vm._v(" = angefragtes Kontingent\n        "),
      _c("i", { staticClass: "material-icons" }, [_vm._v("person_add")]),
      _vm._v(" = Extrazimmer für Busfahrer, Reiseleiter, etc.\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }