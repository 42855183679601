var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "placeholder", visible: _vm.isModalVisible },
      on: { closeModal: _vm.closeModal },
    },
    [
      _vm.modalLoading
        ? [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("loading", {
                  attrs: { textInFront: "Daten werden aktualisiert" },
                }),
              ],
              1
            ),
          ]
        : [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "is-saving" }, [
                _vm.isSaving === "ok"
                  ? _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check"),
                    ])
                  : _vm.isSaving
                  ? _c("i", { staticClass: "material-icons icon-spin" }, [
                      _vm._v("refresh"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.localPlaceholder.startAirport.place.name) +
                    " - " +
                    _vm._s(_vm.localPlaceholder.endAirport.place.name) +
                    "\n            "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header-extra-info" }, [
                _c(
                  "div",
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Flug" },
                      },
                      [_vm._v("airplanemode_active")]
                    ),
                    _vm._v(" "),
                    _c("async-select", {
                      ref: "startAirport",
                      staticClass: "has-margin-right is-medium",
                      attrs: {
                        api: "airports",
                        "custom-label": (airport) =>
                          airport.name + " - " + airport.place.name,
                        crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
                        clearable: false,
                        "allow-empty": false,
                        placeholder: "Flughafen finden",
                      },
                      model: {
                        value: _vm.localPlaceholder.startAirport,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "startAirport", $$v)
                        },
                        expression: "localPlaceholder.startAirport",
                      },
                    }),
                    _vm._v(" "),
                    _c("async-select", {
                      staticClass: "has-margin-right is-medium",
                      attrs: {
                        api: "airports",
                        "custom-label": (airport) =>
                          airport.name + " - " + airport.place.name,
                        crudLinkTitle: "Fügen Sie einen neuen Flughafen hinzu",
                        clearable: false,
                        "allow-empty": false,
                        placeholder: "Flughafen finden",
                      },
                      model: {
                        value: _vm.localPlaceholder.endAirport,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "endAirport", $$v)
                        },
                        expression: "localPlaceholder.endAirport",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("access_time"),
                    ]),
                    _vm._v(" "),
                    _c("date-time", {
                      staticClass: "has-margin-right",
                      model: {
                        value: _vm.localPlaceholder.startAt,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "startAt", $$v)
                        },
                        expression: "localPlaceholder.startAt",
                      },
                    }),
                    _vm._v(" -\n                    "),
                    _c("date-time", {
                      staticClass: "has-margin-left",
                      model: {
                        value: _vm.localPlaceholder.endAt,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "endAt", $$v)
                        },
                        expression: "localPlaceholder.endAt",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "is-block" }, [
                  _c("div", { staticClass: "level mb-1" }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Set as Additional" },
                      },
                      [_vm._v("shopping_cart")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localPlaceholder.clientOffer.status,
                              expression: "localPlaceholder.clientOffer.status",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.localPlaceholder.clientOffer,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "included" } }, [
                            _vm._v("inkludiert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "optional" } }, [
                            _vm._v("optional"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "additional" } }, [
                            _vm._v("gesondert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "atLocation" } }, [
                            _vm._v("vor Ort"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "level mb-1" },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("directions_bus"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.localPlaceholder.driverNotParticipating,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder,
                                "driverNotParticipating",
                                $$v
                              )
                            },
                            expression:
                              "localPlaceholder.driverNotParticipating",
                          },
                        },
                        [
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("mit"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("ohne Fahrer"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large is-success is-add-new",
                  on: { click: _vm.addNewRequest },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Leistungsträger hinzufügen"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm._l(
                  _vm.localPlaceholder.requests,
                  function (request, index) {
                    return !_vm.isNewRequestVisible && request.id
                      ? _c("request", {
                          key: request.id,
                          attrs: {
                            placeholderInfo: _vm.placeholderInfo,
                            calculationRequest: _vm.calculationRequest,
                          },
                          on: {
                            closeModal: function ($event) {
                              return _vm.$emit(
                                "closeModal",
                                _vm.localPlaceholder
                              )
                            },
                            setCalculationRequest: _vm.setCalculationRequest,
                            saved: _vm.handleRequestSaved,
                          },
                          model: {
                            value: _vm.localPlaceholder.requests[index],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder.requests,
                                index,
                                $$v
                              )
                            },
                            expression: "localPlaceholder.requests[index]",
                          },
                        })
                      : _vm._e()
                  }
                ),
                _vm._v(" "),
                _vm.isNewRequestVisible
                  ? _c("new-request", {
                      attrs: {
                        placeholderID: _vm.localPlaceholder.id,
                        placeholderInfo: _vm.placeholderInfo,
                      },
                      on: {
                        close: _vm.closeNewRequestModal,
                        closeModal: function ($event) {
                          return _vm.$emit("closeModal", _vm.localPlaceholder)
                        },
                        saved: _vm.handleRequestSaved,
                      },
                      model: {
                        value:
                          _vm.localPlaceholder.requests[
                            _vm.localPlaceholder.requests.length - 1
                          ],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localPlaceholder.requests,
                            _vm.localPlaceholder.requests.length - 1,
                            $$v
                          )
                        },
                        expression:
                          "localPlaceholder.requests[localPlaceholder.requests.length - 1]",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }