var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "placeholder", visible: _vm.isModalVisible },
      on: { closeModal: _vm.closeModal },
    },
    [
      _vm.modalLoading
        ? [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("loading", {
                  attrs: { textInFront: "Daten werden aktualisiert" },
                }),
              ],
              1
            ),
          ]
        : [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "is-saving" }, [
                _vm.isSaving === "ok"
                  ? _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check"),
                    ])
                  : _vm.isSaving
                  ? _c("i", { staticClass: "material-icons icon-spin" }, [
                      _vm._v("refresh"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "name" },
                [
                  _vm.localPlaceholder.serviceDescription
                    ? [
                        _vm._v(
                          _vm._s(_vm.localPlaceholder.serviceDescription) + " "
                        ),
                      ]
                    : [
                        _vm._v(
                          _vm._s(_vm.localPlaceholder.otherServiceType.name) +
                            " "
                        ),
                      ],
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "bp-row-form extra-info" }, [
                _c("div", [
                  _c("div", [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { "font-size": "21px" },
                      },
                      [_vm._v("explore")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.categorySelected,
                              expression: "categorySelected",
                            },
                          ],
                          ref: "category",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.categorySelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { disabled: "", selected: "" },
                              domProps: { value: null },
                            },
                            [_vm._v("Kategorie")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.categories, function (category) {
                            return _c(
                              "option",
                              { domProps: { value: category.key } },
                              [_vm._v(_vm._s(category.value))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "button",
                        {
                          class: [
                            "btn-single-toggle",
                            _vm.onlyFromOrder ? "" : "is-active",
                          ],
                          staticStyle: { border: "1px solid #dddddd" },
                          attrs: {
                            title: _vm.onlyFromOrder
                              ? "Ortsangaben werden nur in der Reisedestination gesucht (klicken zum Deaktivieren)"
                              : "Alle Orte werden gesucht (zum Filtern nach Reise-Destinationen klicken)",
                          },
                          on: {
                            click: function ($event) {
                              _vm.onlyFromOrder = !_vm.onlyFromOrder
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("flag"),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("location-select", {
                        ref: "location",
                        attrs: {
                          value: {
                            area: _vm.localPlaceholder.area,
                            place: _vm.localPlaceholder.place,
                          },
                          isNullable: "",
                          hasDestination: "",
                          limitedDestinations: _vm.destinationsForLocation,
                        },
                        on: {
                          input: (value) => {
                            _vm.localPlaceholder.destination = value.destination
                            _vm.localPlaceholder.area = value.area
                            _vm.localPlaceholder.place = value.place
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "is-flex-grow" },
                  [
                    _c("other-service-type-select", {
                      attrs: {
                        placeholderInfo: _vm.placeholderInfo,
                        "show-service-day-times": true,
                      },
                      model: {
                        value: _vm.localPlaceholder.otherServiceType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localPlaceholder,
                            "otherServiceType",
                            $$v
                          )
                        },
                        expression: "localPlaceholder.otherServiceType",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "i",
                        {
                          staticClass: "material-icons",
                          staticStyle: { "font-size": "21px" },
                          attrs: { title: "Treffpunkt" },
                        },
                        [_vm._v("my_location")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.localPlaceholder.meetingPoint,
                            expression: "localPlaceholder.meetingPoint",
                          },
                        ],
                        staticClass: "input",
                        attrs: { type: "text", placeholder: "Treffpunkt" },
                        domProps: { value: _vm.localPlaceholder.meetingPoint },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.localPlaceholder,
                              "meetingPoint",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "has-time" },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("date_range"),
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "is-static-date" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("dayMonth")(_vm.localPlaceholder.startAt)
                          ) + " "
                        ),
                      ]),
                      _vm._v(
                        "\n                        -\n                        "
                      ),
                      _c("datepicker", {
                        staticClass: "has-margin-left",
                        attrs: {
                          placeholder: "Ende",
                          config: {
                            dateFormat: "d.m.Y H:i:S",
                            minDate: _vm.localPlaceholder.startAt,
                            parseDate: function (dateStr) {
                              return _vm
                                .moment(dateStr, "DD.MM.YYYY HH:mm:ss")
                                .toDate()
                            },
                            formatDate: function (dateObj) {
                              return _vm
                                .moment(dateObj)
                                .format("DD.MM.YYYY HH:mm:ss")
                            },
                          },
                        },
                        model: {
                          value: _vm.localPlaceholder.endAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.localPlaceholder, "endAt", $$v)
                          },
                          expression: "localPlaceholder.endAt",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm.serviceDayTimes.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass: "btn-single-toggle has-margin-right",
                              class: [_vm.onlyTime ? "" : "is-active"],
                              attrs: { title: "Uhrzeit" },
                              on: {
                                click: function ($event) {
                                  _vm.onlyTime = !_vm.onlyTime
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: { "vertical-align": "-1px" },
                                },
                                [_vm._v("access_time")]
                              ),
                            ]
                          )
                        : _c("i", { staticClass: "material-icons" }, [
                            _vm._v("access_time"),
                          ]),
                      _vm._v(" "),
                      _vm.onlyTime
                        ? [
                            _c("date-time", {
                              staticClass: "has-margin-right",
                              model: {
                                value: _vm.localPlaceholder.startAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPlaceholder, "startAt", $$v)
                                },
                                expression: "localPlaceholder.startAt",
                              },
                            }),
                            _vm._v(" -\n                            "),
                            _c("date-time", {
                              staticClass: "has-margin-left",
                              attrs: { placeholderText: "Ende" },
                              model: {
                                value: _vm.localPlaceholder.endAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPlaceholder, "endAt", $$v)
                                },
                                expression: "localPlaceholder.endAt",
                              },
                            }),
                          ]
                        : [
                            _c(
                              "div",
                              { staticClass: "select" },
                              [
                                _c("multiselect", {
                                  attrs: {
                                    options: _vm.serviceDayTimes,
                                    "track-by": "id",
                                    "custom-label": _vm.serviceDayTimeLabel,
                                  },
                                  on: { select: _vm.selectServiceTime },
                                  model: {
                                    value: _vm.serviceDayTimeSelected,
                                    callback: function ($$v) {
                                      _vm.serviceDayTimeSelected = $$v
                                    },
                                    expression: "serviceDayTimeSelected",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("div", [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Set as Additional" },
                      },
                      [_vm._v("shopping_cart")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localPlaceholder.clientOffer.status,
                              expression: "localPlaceholder.clientOffer.status",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.localPlaceholder.clientOffer,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "included" } }, [
                            _vm._v("inkludiert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "optional" } }, [
                            _vm._v("optional"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "additional" } }, [
                            _vm._v("gesondert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "atLocation" } }, [
                            _vm._v("vor Ort"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.includes(
                    ["optional", "additional", "included"],
                    _vm.localPlaceholder.clientOffer.status
                  )
                    ? _c("div", [
                        _c("i", { staticClass: "material-icons" }, [
                          _vm._v("people"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "select" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.localPlaceholder.clientOffer.priceType,
                                  expression:
                                    "localPlaceholder.clientOffer.priceType",
                                },
                              ],
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.localPlaceholder.clientOffer,
                                    "priceType",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "per_person" } }, [
                                _vm._v("Pro Person"),
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "per_group" } }, [
                                _vm._v("Pro Gruppe"),
                              ]),
                            ]
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("directions_bus"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.localPlaceholder.driverNotParticipating,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder,
                                "driverNotParticipating",
                                $$v
                              )
                            },
                            expression:
                              "localPlaceholder.driverNotParticipating",
                          },
                        },
                        [
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("mit"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("ohne Fahrer"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large is-success is-add-new",
                  staticStyle: { top: "33px" },
                  on: { click: _vm.addNewRequest },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Leistungsträger hinzufügen"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm._l(
                  _vm.localPlaceholder.requests,
                  function (request, index) {
                    return !_vm.isNewRequestVisible && request.id
                      ? _c("request", {
                          key: request.id,
                          attrs: {
                            placeholderInfo: _vm.placeholderInfo,
                            calculationRequest: _vm.calculationRequest,
                          },
                          on: {
                            closeModal: function ($event) {
                              return _vm.$emit(
                                "closeModal",
                                _vm.localPlaceholder
                              )
                            },
                            setCalculationRequest: _vm.setCalculationRequest,
                            saved: _vm.handleRequestSaved,
                          },
                          model: {
                            value: _vm.localPlaceholder.requests[index],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder.requests,
                                index,
                                $$v
                              )
                            },
                            expression: "localPlaceholder.requests[index]",
                          },
                        })
                      : _vm._e()
                  }
                ),
                _vm._v(" "),
                _vm.isNewRequestVisible
                  ? _c("new-request", {
                      attrs: {
                        placeholderID: _vm.localPlaceholder.id,
                        placeholderInfo: _vm.placeholderInfo,
                      },
                      on: {
                        close: _vm.closeNewRequestModal,
                        closeModal: function ($event) {
                          return _vm.$emit("closeModal", _vm.localPlaceholder)
                        },
                        saved: _vm.handleRequestSaved,
                      },
                      model: {
                        value:
                          _vm.localPlaceholder.requests[
                            _vm.localPlaceholder.requests.length - 1
                          ],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localPlaceholder.requests,
                            _vm.localPlaceholder.requests.length - 1,
                            $$v
                          )
                        },
                        expression:
                          "localPlaceholder.requests[localPlaceholder.requests.length - 1]",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }