<template>
    <div>
        <div class="bp-box is-info">
            <header><i class="material-icons">info</i> Informationen zur Anfrage</header>

            <main>
                <div class="is-one-row" v-if="!request.otherService || editMode">

                    <div>
                        <button
                            class        = "btn-single-toggle"
                            v-bind:class = "[onlyAssignedProviders ? 'is-active' : '']"
                            v-on:click   = "onlyAssignedProviders = !onlyAssignedProviders"
                            style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                            :title = "onlyAssignedProviders ? 'Klicken, um auch nicht zugewiesene Leistungsträger zu finden' : 'Klicken, um nur zugewiesene Leistungsträger anzuzeigen'"
                        ><i class="material-icons" style="margin: 0;">{{onlyAssignedProviders ? 'lock' : 'lock_open'}}</i></button>
                        Leistungsträger:
                        <async-select
                            class="is-shorter"
                            style="width: 300px"
                            api="providers"
                            placeholder="No-Name"
                            v-model="selectedProvider"
                            @input="handleProviderAgencyChange"
                            :params="{...onlyAssignedProviders &&
                                {'otherServices.otherServiceType.id': placeholderInfo.otherServiceType.id,
                                 'providerWithLocation.place.id': placeholderInfo.place && placeholderInfo.place.id,
                                 'providerWithLocation.place.area.id': placeholderInfo.area && placeholderInfo.area.id,
                                 'providerWithLocation.place.destination.id': placeholderInfo.destination && placeholderInfo.destination.id},
                                _groups: ['provider_read']}"
                        />
                    </div>

                    <agency-select
                            multiselect-class="is-shorter"
                            v-if="editMode"
                            v-model="selectedAgency"
                            v-bind:provider = "null"
                            @input="handleProviderAgencyChange"
                            params="&_groups[]=other_service_read"
                            :prefill="true"
                            text            = "Agentur"
                    ></agency-select>

                    <div v-if="showOtherServiceSubTitleSelect">
                        Zusatztitel:
                        <other-service-select
                            :sub-title-only="true"
                            :agency-exist="!!selectedAgency"
                            :agencyID="selectedAgency ? selectedAgency.id : null"
                            v-bind:providerID="selectedProvider ? selectedProvider.id : null"
                            class="has-margin-left is-shorter"
                            v-model="request.otherService"
                            v-bind:otherServiceTypeID="placeholderInfo.otherServiceType.id"
                        ></other-service-select>
                    </div>
                </div>
                <div class="is-one-row">

                    <div>
                        <i class="material-icons">import_contacts</i> Status:
                        <div class="select">
                            <select v-model="request.requestStatus.status">
                                <template v-for="status in requestStatuses">
                                    <option v-bind:value="status.key">{{ status.value }}</option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div>
                      <i class="material-icons">notifications_none</i> Termine:
                      <multiselect
                          v-bind:searchable="false"
                          class="is-shorter"
                          v-if="request.todos && showSelectList"
                          style="min-width: 180px"
                          v-model="selectedTodo"
                          :options="request.todos"
                          v-bind:custom-label="todoLabel"
                          track-by="id"
                      />
                      <input
                          type="text"
                          class="input"
                          v-if="showTodoInput"
                          v-model="selectedTodo.name"
                      />
                      <datepicker
                          v-if="selectedTodo"
                          v-bind:inputClass="['is-date']"
                          v-bind:config="{
                            dateFormat: 'd.m.Y H:i:S',
                            wrap: true,
                            parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY HH:mm:ss').toDate(); },
                            formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY HH:mm:ss'); },
                            allowInput: true,
                            altInput: true,
                        }"
                          v-model="deadline"
                          placeholder="Deadline"
                      />
                      <button @click="addTodo">
                        <i class="material-icons">add</i>
                      </button>
                      <button v-if="request.todos.length > 1" @click="deleteTodoInput(request.optionTodo)">
                        <i class="material-icons">delete</i>
                      </button>
                    </div>

                    <div >
                        <i class="material-icons">format_list_numbered</i> Reservierungs-Nr:
                        <input type="text" class="input" v-model="request.optionNr" />
                    </div>

                    <div>
                        <i class="material-icons">contact_phone</i> Ansprechpartner:
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.requestContact"
                            v-bind:options="this.request.agency ? this.agencyContactOptions : this.providerContactOptions"
                            track-by="id"
                            :custom-label="contact => `${contact.fullName}`"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>
                </div>

                <div class="is-last-row" v-if="showAssignedContactAddress">
                    <div>
                        <i class="material-icons">list</i> Für Kunde sichtbar:
                        <button
                            title="Adresse anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('address')}"
                            @click="setVisibleContactDetails('address')"
                        ><i class="material-icons">home</i></button>
                        <button
                            title="Speziellen Kontakt anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('contact')}"
                            @click="setVisibleContactDetails('contact')"
                        ><i class="material-icons">contact_phone</i></button>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">home</i> Adresse
                        <multiselect
                            class="is-shorter"
                            style="min-width: 150px"
                            v-model="request.assignedAddress"
                            v-bind:options="providerAddressOptions"
                            track-by="id"
                            :custom-label="address => (address.title ? address.title + ': ' + address.streetAddress : address.streetAddress).substring(0,50)"
                            :to-be-copied="false"
                            placeholder="Keine Adresse"

                        >
                        </multiselect>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('contact')">
                        <i class="material-icons">contact_phone</i>
                            Kontakt:
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.assignedContact"
                            v-bind:options="providerContactOptions.concat(agencyContactOptions).filter(item => item.role !== 'general_contact')"
                            track-by="id"
                            :custom-label="contact => contact.fullName ? `${contact.fullName}` : 'Allgemein'"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address') || request.visibleContactDetails.includes('contact')">
                        <i class="material-icons">list</i> Kontaktdetails sichtbar:
                        <button
                            title="Festnetz anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneFixed')}"
                            @click="setVisibleContactDetails('phoneFixed')"
                        ><i class="material-icons">phone</i></button>
                        <button
                            title="Mobile anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneMobile')}"
                            @click="setVisibleContactDetails('phoneMobile')"
                        ><i class="material-icons">smartphone</i></button>
                    </div>
                </div>







            </main>
        </div>

        <div>
            <toggle-switch v-model="hasConnectedContingent">
                <option :value="true"><i class="material-icons mr-2">link</i> Mit Kundenkontingent verknüpfen</option>
                <option :value="false"><i class="material-icons mr-2">link_off</i> Individuelles Kontingent eingeben</option>
            </toggle-switch>
        </div>
        <br>
        <div class="columns">
            <div class="column">
                <div class="bp-box is-tickets">
                    <header>
                        <div>
                            <other-service-icons v-bind:type="request.otherServiceType.category"></other-service-icons> Zusatzleistung
                        </div>
                        <div>
                            <i class="material-icons">check_circle</i>
                            <i class="material-icons">help</i>
                        </div>
                        <div><button v-if="!request.package" title="Extrabuchung für Reisebegleitung" v-on:click="addExtra"><i class="material-icons">person_add</i></button></div>
                    </header>

                    <main>
                        <div>
                            <div>
                                <i class="material-icons">person</i> Teilnehmer
                            </div>
                            <div>
                                <input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.reservedAmount"
                                    v-on:focus="$event.target.select()"
                                    v-bind:disabled="hasConnectedContingent"
                                />
                                <input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.askedAmount"
                                    v-on:focus="$event.target.select()"
                                    v-bind:disabled="hasConnectedContingent"
                                />
                            </div>
                            <div><button
                                class="button"
                                title="Copy value"
                                v-on:click="copContingent"
                            >
                                <i class="material-icons">check_circle</i>
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                <i class="material-icons">help</i>
                            </button></div>
                        </div>

                        <div class="extra-people" v-if="hasExtra && !request.package">
                            <div>
                                <i class="material-icons">person_add</i>
                                <input
                                    class="input"
                                    type="text"
                                    v-model="request.contingent.extraDescription"
                                />
                                <button class="btn-delete" title="Löschen" v-on:click="deleteExtra" tabindex="-1"><i class="material-icons">delete</i></button>
                            </div>
                            <div>
                                <div class="control"><input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.extraReservedAmount"
                                    v-on:focus="$event.target.select()"
                                /></div>
                                <div class="control"><input
                                    class="input"
                                    type="text"
                                    v-model.number="request.contingent.extraAskedAmount"
                                /></div>
                            </div>
                            <div><button
                                class="button"
                                title="Copy value"
                                v-on:click="copyExtraContingent"
                            >
                                <i class="material-icons">check_circle</i>
                                <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                                <i class="material-icons">help</i>
                            </button></div>
                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <tip-tap v-model="request.notes" />
            </div>
            <div class="column">
                <item-prices
                    :is-package="true"
                    v-if="request.package"
                    v-bind:isWithoutPrice="false"
                    v-model="request.package.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
                <item-prices
                    v-else
                    v-bind:isWithoutPrice="false"
                    v-model="request.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
            </div>
        </div>


        <p class="explanation">
            <i class="material-icons">check_circle</i> = bestätigtes Kontingent
            <i class="material-icons">help</i> = angefragtes Kontingent
            <i class="material-icons">person_add</i> = Extrazimmer für Busfahrer, Reiseleiter, etc.
        </p>
    </div>
</template>



<script>
import get                from 'lodash/get';
import OtherServiceIcons  from '@components/OtherServiceIcons';
import OtherServiceSelect from '@orders/placeholders/components/forms/OtherServiceSelect';
import mixins             from './mixins.js';
import AsyncSelect from "@components/form/AsyncSelect.vue";
import {getAxios, post} from "@api";
import {notifySuccess} from "@components/Notification";


export default {
    mixins: [mixins],


    props: {
        placeholderInfo: { type: Object, required: true }
    },


    data: function () {
        return {
            hasExtra: false,
            onlyAssignedProviders: true,
            selectedProvider: this.value.otherService ? this.value.otherService.provider : null,
            selectedAgency: this.value.agency,
            showOtherServiceSubTitleSelect: false,
        };
    },

    computed: {
        provider: function () { return this.request.otherService && this.request.otherService.provider ? this.request.otherService.provider : null },
        providerContacts: function () { return get(this.request, 'otherService.provider.contacts', []); },
        hasConnectedContingent: {
            get: function () {
                return this.request.hasConnectedContingent;
            },
            set: function (val) {
                this.request.hasConnectedContingent = val
            }
        }
    },


    methods: {
        getAvailableOtherServices () {
            return new Promise((resolve, reject) => {
                this.getOtherServices().then(otherServices => {
                    if(this.selectedAgency && !this.selectedProvider) {
                        otherServices = otherServices.filter(service => service.otherServiceType.id === this.placeholderInfo.otherServiceType.id && !service.provider)
                    }  else if (this.selectedAgency) {
                        otherServices = otherServices.filter(service => service.otherServiceType.id === this.placeholderInfo.otherServiceType.id && service.agency && service.agency.id === this.selectedAgency.id)
                    } else if(this.selectedProvider) {
                        otherServices = otherServices.filter(service => service.otherServiceType.id === this.placeholderInfo.otherServiceType.id && !service.agency)
                    } else {
                        otherServices = [];
                    }
                    this.showOtherServiceSubTitleSelect = otherServices.length > 1;
                    resolve(otherServices);
                })
            });
        },

        getOtherServices () {
            return new Promise((resolve, reject) => {
                if(this.selectedProvider) {
                    getAxios('providers/' + this.selectedProvider.id, {_groups: ['other_service_read', 'agency_read', 'other_service_type_read']}).then(response => {
                        Object.assign(this.selectedProvider, {otherServices: response.data.otherServices});
                        resolve(response.data.otherServices)
                    })
                }
                else if(this.selectedAgency) {

                    getAxios('agencies/' + this.selectedAgency.id, {_groups: ['other_service_read', 'agency:provider_other_service']}).then(response => {
                        Object.assign(this.selectedAgency, {otherServices: response.data.otherServices});
                        resolve(response.data.otherServices)
                    })
                } else {
                    resolve([])
                }
            });
        },

        handleProviderAgencyChange () {
            this.getAvailableOtherServices().then(otherServices => {
                this.request.provider = this.selectedProvider;
                this.request.agency = this.selectedAgency;
                if(otherServices.length > 0) {
                    this.request.otherService = otherServices[0];
                } else if(this.selectedProvider || this.selectedAgency) {
                    this.assignOtherService();
                } else {
                    this.request.otherService = null;
                }
            })
        },

        assignOtherService() {
            post('other_services', {
                ...this.selectedProvider && {provider: '/api/providers/' + this.selectedProvider.id},
                ...this.selectedAgency && {agency: '/api/agencies/' + this.selectedAgency.id},
                otherServiceType: '/api/other_service_types/' + this.placeholderInfo.otherServiceType.id,
            }).then(response => {
                this.request.otherService = response.data;
                notifySuccess('Die Leistung wurde dem Leistungsträger neu zugeordnet')
            })
        },

        addExtra: function () {
            this.request.contingent.extraDescription    = '';
            this.request.contingent.extraReservedAmount = 0;
            this.request.contingent.extraAskedAmount    = 0;
            this.hasExtra = true;
        },


        deleteExtra: function () {
            this.request.contingent.extraDescription    = null;
            this.request.contingent.extraReservedAmount = null;
            this.request.contingent.extraAskedAmount    = null;
            this.hasExtra = false;
        },


        copContingent: function () {
            this.request.contingent.reservedAmount = this.request.contingent.askedAmount;
            this.request.contingent.askedAmount    = 0;
        },


        copyExtraContingent: function () {
            this.request.contingent.extraReservedAmount = this.request.contingent.extraAskedAmount;
            this.request.contingent.extraAskedAmount    = 0;
        }
    },


    created: function () {
        this.hasExtra = !!(this.request.contingent.extraAskedAmount ||
            this.request.contingent.extraReservedAmount);
    },
    mounted: function() {
        this.fetchContactOptions();
    },

    components: {
        AsyncSelect,
        OtherServiceIcons,
        OtherServiceSelect
    },


    watch: {
        // Fixing empty strins to 0 (zero)
        'request.contingent.askedAmount': function () {
            if (this.request.contingent.askedAmount === '') {
                this.request.contingent.askedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.reservedAmount': function () {
            if (this.request.contingent.reservedAmount === '') {
                this.request.contingent.reservedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.extraAskedAmount': function () {
            if (this.request.contingent.extraAskedAmount === '') {
                this.request.contingent.extraAskedAmount = 0;
            }
        },


        // Fixing empty strins to 0 (zero)
        'request.contingent.extraReservedAmount': function () {
            if (this.request.contingent.extraReservedAmount === '') {
                this.request.contingent.extraReservedAmount = 0;
            }
        },
        'editMode': function () {
            if (this.editMode) {
                this.getAvailableOtherServices();
            }
        },
    }
}
</script>
