<template>
    <div class="has-icon">
        <i class="fa fa-book"></i> <template v-if="text">{{ text }}: </template>

        <multiselect
            :class="multiselectClass"
            v-model        = "agencySelected"
            v-bind:options = "agencies"
            v-bind:loading = "isLoading"
            v-bind:class   = "[text ? 'has-margin-left' : '']"
            placeholder    = "Agentur suchen"
            track-by       = "id"
            label          = "name"
            v-bind:allow-empty = "true"
            v-on:search-change = "agencySearch"
        ><span slot="caret" v-if="isEmptyResults">
            <a
                class  = "multiselect-crud-link"
                href   = "/agencies?add"
                title  = "Neue Agentur hinzufügen"
                target = "_blank"
            ><i class="material-icons">add</i></a>
        </span></multiselect>
    </div>
</template>



<script>
import get             from 'lodash/get'
import debounce        from 'lodash/debounce';
import { Multiselect } from '@components/form';
import { getAgencies } from '@api';
import mixins from "@orders/placeholders/components/forms/mixins";
import {notifyError} from "@components/Notification";
import axios from "axios";


export default {
    mixins: [mixins],
    props: {
        provider: { required: true },
        text:     { type: String,  default: '' },
        prefill:  { type: Boolean, default: false },
        value:    { type: Object,  required: false },
        multiselectClass: {},
        params: {default: ''}
    },


    data: function () {
        return {
            agencySelected:  !!this.value ? JSON.parse(JSON.stringify(this.value)) : null,
            agenciesList:    [],
            isLoading:       false,
            isEmptyResults:  false
        }
    },

    computed: {
        agencies: function() {
            if (this.provider) {
                return this.provider.agencies
            }

            return this.agenciesList
        }
    },

    methods: {
        agencySearch: debounce(function (query, isTriggerAction = false) {
            if (query || isTriggerAction) {
                this.isLoading = true;
                this.callCancelRequest();
                getAgencies('?_search=' + query + this.params, {cancelToken: this.cancelSource.token})
                    .then(response => {
                        this.agenciesList   = response.data;
                        this.isEmptyResults = response.data.length === 0;

                    }, error => {
                        if(!axios.isCancel(error)) {
                            notifyError('Die Agenturen konnten nicht geladen werden! Server error!');
                        }
                    })
                    .then(() => { this.isLoading = false; });
            }
        }, 900),
    },


    created: function () {
        if (this.prefill) {
            this.agencySearch('', true);
        }
    },


    watch: {
        provider: function (newVal, oldVal) {
            if (get(newVal, 'id', null) !== get(oldVal, 'id', null)) {
                this.agencySelected = null;
            }
        },


        agencySelected: function () {
            this.$emit('update', this.agencySelected)
            this.$emit('input', this.agencySelected)

        }
    },


    components: {
        Multiselect
    }
}
</script>
