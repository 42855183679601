<template>
    <main>
        <slot />

        <div v-if="isNightFerry">
            <div>Überfahrt</div>
            <input-price
                v-model="ticketPrice"
                :provider-prices="value.providerPrices.ticket_price"
                :small-input="true"
                :price-currency="requestCurrency"
            />
        </div>
        <div>
            <div>Buspreis</div>
            <input-price
                v-model="busPrice"
                :provider-prices="value.providerPrices.bus_price"
                :small-input="true"
                :price-currency="requestCurrency"
            />
        </div>

        <div>
            <div>Steuern - Bus</div>
            <input-price
                v-model="busTax"
                :provider-prices="value.providerPrices.bus_tax"
                :small-input="true"
                :price-currency="requestCurrency"
            />
        </div>

        <div>
            <div>Steuern - pro Person</div>
            <input-price
                v-model="ticketTax"
                :provider-prices="value.providerPrices.ticket_tax"
                :small-input="true"
                :price-currency="requestCurrency"
            />
        </div>
    </main>
</template>

<script>

import InputPrice from '@calculations/components/InputPrice'

export default {
    components: { InputPrice },

    props: {
        value: {
            type: Object,
            required: true
        },
        isNightFerry: {
            type: Boolean,
            required: false,
        },
        requestCurrency: {
            type: String,
            required: true
        },
    },

    computed: {
        busPrice: {
            get: function () { return this.value.busPrice.amount },
            set: function (value) { this.$emit('input', {...this.value,
                busPrice: {
                    amount: value,
                    currency: this.requestCurrency
                }
            }) }
        },

        busTax: {
            get: function () { return this.value.busTax.amount },
            set: function (value) { this.$emit('input', {...this.value,
                busTax: {
                    amount: value,
                    currency: this.requestCurrency
                }}) }
        },

        ticketTax: {
            get: function () { return this.value.ticketTax.amount },
            set: function (value) { this.$emit('input', {...this.value, ticketTax: {
                    amount: value,
                    currency: this.requestCurrency
                }}) }
        },

        ticketPrice: {
            get: function () { return this.value.ticketPrice.amount },
            set: function (value) { this.$emit('input', {...this.value,
                ticketPrice: {
                    amount: value,
                    currency: this.requestCurrency
                }}) }
        }
    }
}
</script>

