<template>
    <div>
        <div class="bp-box is-info">
            <header><i class="material-icons">info</i> Informationen zur Anfrage</header>

            <main>
                <div class="is-one-row" v-if="!request.hotel || editMode">
                    <button
                        v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                        v-on:click="onlyFromOrder = !onlyFromOrder"
                        v-bind:title="onlyFromOrder ? 'Suche ist auf obigen Ort eingeschränkt' : 'Suche ist nicht eingeschränkt'"
                        style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                    ><i class="material-icons" style="margin: 0">flag</i></button>

                    <hotel-select
                        v-model                = "request.hotel"
                        v-bind:placeholderInfo = "onlyFromOrder ? localPlaceholderInfo : {area:null, place:null}"
                        :show-icon="false"
                        multi-select-style="width: 400px"
                    ></hotel-select>

                    <agency-select
                        v-if="editMode"
                        v-bind:value    = "request.agency"
                        v-bind:provider = "null"
                        text            = "Agentur"
                        prefill
                        v-on:update     = "agency => request.agency = agency"
                    ></agency-select>
                </div>

                <div class="is-one-row">
                    <div>
                        <i class="material-icons">import_contacts</i> Status:
                        <div class="select">
                            <select v-model="request.requestStatus.status">
                                <template v-for="status in requestStatuses">
                                    <option v-bind:value="status.key">{{ status.value }}</option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div>
                        <i class="material-icons">notifications_none</i> Termine:
                        <multiselect
                            v-bind:searchable="false"
                            class="is-shorter"
                            v-if="request.todos && showSelectList"
                            style="min-width: 180px"
                            v-model="selectedTodo"
                            :options="request.todos"
                            v-bind:custom-label="todoLabel"
                            track-by="id"
                        />
                        <input
                            type="text"
                            class="input"
                            v-if="showTodoInput"
                            v-model="selectedTodo.name"
                        />
                        <datepicker
                            v-if="selectedTodo"
                            v-bind:inputClass="['is-date']"
                            v-bind:config="{
                            dateFormat: 'd.m.Y H:i:S',
                            wrap: true,
                            parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY HH:mm:ss').toDate(); },
                            formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY HH:mm:ss'); },
                            allowInput: true,
                            altInput: true,
                        }"
                            v-model="deadline"
                            placeholder="Deadline"
                        />
                        <button @click="addTodo">
                            <i class="material-icons">add</i>
                        </button>
                        <button v-if="request.todos.length > 1" @click="deleteTodoInput(request.optionTodo)">
                            <i class="material-icons">delete</i>
                        </button>
                    </div>

                    <div>
                        <label><i class="material-icons">format_list_numbered</i> Reservierungs-Nr:</label>
                        <input type="text" class="input" v-model="request.optionNr" />
                    </div>
                    <div>
                        <label><i class="material-icons">contact_phone</i> Ansprechpartner:</label>
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.requestContact"
                            v-bind:options="this.request.agency ? this.agencyContactOptions : this.providerContactOptions"
                            track-by="id"
                            :custom-label="contact => `${contact.fullName}`"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>
                </div>
                <div class="is-last-row" v-if="showAssignedContactAddress">

                    <div>
                        <label><i class="material-icons">list</i> Für Kunde sichtbar:</label>
                        <button
                            title="Adresse anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('address')}"
                            @click="setVisibleContactDetails('address')"
                        ><i class="material-icons">home</i></button>
                    </div>

                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">home</i> Adresse
                        <multiselect
                            class="is-shorter"
                            style="min-width: 150px"
                            v-model="request.assignedAddress"
                            v-bind:options="providerAddressOptions"
                            track-by="id"
                            :custom-label="address => (address.title ? address.title + ': ' + address.streetAddress : address.streetAddress).substring(0,50)"
                            :to-be-copied="false"
                            placeholder="Keine Adresse"

                        >
                        </multiselect>
                    </div>
                    <div v-if="request.visibleContactDetails.includes('address')">
                        <i class="material-icons">list</i> Kontaktdetails sichtbar:
                        <button
                            title="Festnetz anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneFixed')}"
                            @click="setVisibleContactDetails('phoneFixed')"
                        ><i class="material-icons">phone</i></button>
                        <button
                            title="Mobile anzeigen"
                            class="button is-icon-button"
                            :class="{'is-active': request.visibleContactDetails.includes('phoneMobile')}"
                            @click="setVisibleContactDetails('phoneMobile')"
                        ><i class="material-icons">smartphone</i></button>
                    </div>



                </div>



            </main>
        </div>
        <div>
            <toggle-switch v-model="hasConnectedContingent">
                <option :value="true"><i class="material-icons mr-2">link</i> Mit Kundenkontingent verknüpfen</option>
                <option :value="false"><i class="material-icons mr-2">link_off</i> Individuelles Kontingent eingeben</option>
            </toggle-switch>
        </div>
        <br>
        <div class="columns">
            <div class="column">
                <simple-hotel-contingent
                    v-if="request.contingent"
                    v-model="request.contingent.doubleRooms"
                    type="hotel"
                    v-bind:nrOfPersons="2"
                    v-bind:types="hotelTypes"
                    v-bind:isInPackage="!!request.package"
                    v-bind:hasConnectedContingent="request.hasConnectedContingent"
                ></simple-hotel-contingent>
            </div>

            <div class="column">
                <simple-hotel-contingent
                    v-if="request.contingent"
                    v-model="request.contingent.singleRooms"
                    type="hotel"
                    v-bind:nrOfPersons="1"
                    v-bind:types="hotelTypes"
                    v-bind:isInPackage="!!request.package"
                    v-bind:hasConnectedContingent="request.hasConnectedContingent"
                ></simple-hotel-contingent>
            </div>

            <div class="column">
                <hotel-contingent
                    v-if="request.contingent"
                    v-model="request.contingent.otherRooms"
                    type="hotel"
                    v-bind:types="hotelTypes"
                    v-bind:hasConnectedContingent="request.hasConnectedContingent"
                ></hotel-contingent>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <tip-tap v-model="request.notes" />
            </div>
            <div class="column">
                <item-prices
                    :is-package="true"
                    v-if="request.package"
                    v-bind:isWithoutPrice="false"
                    v-model="request.package.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
                <item-prices
                    v-else
                    v-bind:isWithoutPrice="false"
                    v-model="request.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
            </div>
        </div>


        <p class="explanation">
            <i class="material-icons">check_circle</i> = bestätigtes Kontingent
            <i class="material-icons">help</i> = angefragtes Kontingent
            <i class="material-icons">person_add</i> = Extrazimmer für Busfahrer, Reiseleiter, etc.
        </p>
    </div>
</template>



<script>
import mixins from './mixins.js';
import LocationSelect        from '@components/form/LocationSelect';
import HotelSelect           from '@orders/placeholders/components/forms/HotelSelect';
import Board                 from '@orders/placeholders/components/forms/HotelBoard';
import HotelContingent       from '../components/contingent/Hotel';
import SimpleHotelContingent from '../components/contingent/SimpleHotel';
import ToggleSwitch from "@components/form/ToggleSwitch";


export default {
    mixins: [mixins],


    props: {
        placeholderInfo: { type: Object, required: true }
    },


    computed: {
        boardList:  function () { return this.$store.state.options.boardList; },
        hotelTypes: function () { return this.$store.state.optionsRoomTypes; },
        provider:   function () { return this.request.hotel },
        hasConnectedContingent: {
            get: function() {
                return this.request.hasConnectedContingent ?? false
            },
            set: function(val) {
                this.request.hasConnectedContingent = val;
            },
        }
    },


    data: function () {
        return {
            localPlaceholderInfo: JSON.parse(JSON.stringify(this.placeholderInfo)),
        }
    },

    mounted: function() {
        this.fetchContactOptions();
    },

    components: {
        ToggleSwitch,
        Board,
        HotelSelect,
        HotelContingent,
        LocationSelect,
        SimpleHotelContingent
    }
}
</script>
