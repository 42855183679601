<template>
    <div class="bp-panel is-form-contingent">
        <div class="panel-label">Fähre - MBZ</div>
        <header>
            <div>&ge; 3 x <i class="material-icons">directions_boat</i></div>
            <div><i class="material-icons" v-if="!isOnlyAsked">check_circle</i></div>
            <div><i class="material-icons">help</i></div>
            <div>
                <button class="has-margin-top" v-on:click="addAccomodationType" title="Einen neuen Kabinentyp hinzufügen"><i aria-hidden="true" class="fa fa-plus-circle"></i></button>
            </div>
        </header>


        <main>
            <div class="row" v-for="(accomodation, index) in accomodations" v-bind:key="accomodation.id">
                <div v-if="editIndex !== index">
                    <span>{{ accomodation.type.persons }}-Bettkabine - {{ getValueWithKey({ key: accomodation.type.type , optionList: types }) }}</span>

                    <div>
                        <button class="btn-delete" title="Löschen" v-on:click="removeAccomodation(accomodation)"><i class="material-icons">delete</i></button>
                        <button class="btn-edit" title="Editieren" v-on:click="editIndex = index"><i class="fa fa-pencil"></i></button>
                    </div>
                </div>

                <div class="multiselect-is-small" v-else>
                    <div class="select">
                        <select v-model.number="accomodation.type.persons">
                            <option v-for="nr in Object.keys(typesForNumber)" v-bind:value="nr">{{ nr }}x</option>
                        </select>
                    </div>

                    <multiselect
                        v-model="accomodation.type.type"
                        v-bind:options="typesForNumber[accomodation.type.persons]"
                        v-bind:taggable="false"
                        placeholder="Kabinentyp"
                        label="value"
                        track-by="key"
                        tag-placeholder="Add this as new tag"
                        select-label=""
                        deselect-label="Löschen"
                        v-on:input="accomodationTypeSelected(accomodation)"
                        v-on:close="closeMultiselect"
                    ></multiselect>
                </div>


                <div>
                    <input
                        v-if="!isOnlyAsked"
                        class="input is-small"
                        type="text"
                        v-model.number="accomodation.reservedAmount"
                        v-on:focus="$event.target.select()"
                        v-bind:disabled="hasConnectedContingent"
                    />
               </div>

                <div>
                    <input
                        class="input is-small"
                        type="text"
                        v-model.number="accomodation.askedAmount"
                        v-on:focus="$event.target.select()"
                        v-bind:disabled="hasConnectedContingent"
                    />
                </div>


                <div><button
                    v-if="!isOnlyAsked"
                    class="button"
                    title="Copy value"
                    v-on:click="setValues(accomodation)"
                >
                    <i class="material-icons">check_circle</i>
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <i class="material-icons">help</i>
                </button></div>
            </div>


            <div class="row" v-bind:class="[accomodations.length > 0 ? 'has-border-top' : '']">
                <div><b>Mehrbettkabine - Gesamt</b></div>
                <div><span v-if="!isOnlyAsked">{{ totalReserved }}</span></div>
                <div><span>{{ totalAsk }}</span></div>
                <div></div>
            </div>
        </main>
    </div>
</template>



<script>
import mixins from './mixins.js';


export default {
    mixins: [mixins],


    computed: {
        typesForNumber: function () {
            let state = {
                3: JSON.parse(JSON.stringify(this.types)),
                4: JSON.parse(JSON.stringify(this.types)),
                5: JSON.parse(JSON.stringify(this.types)),
                6: JSON.parse(JSON.stringify(this.types))
            }

            // Creating the combinations (nr x type) removing existing ones but excluding the editing one
            for (let i = 0, len = this.accomodations.length; i < len; i++) {
                if (this.editIndex !== i) { // excluding the one editing
                    let nr = this.accomodations[i].type.persons;
                    for (let j = 0; j < state[nr].length; j++) {
                        if (state[nr][j].key === this.accomodations[i].type.type) {
                            state[nr].splice(j, 1);
                            j--;
                        }
                    }
                }
            }

            return state;
        }
    },


    methods: {
        addAccomodationType: function () {
            // Suggesting a number and a type
            let firstFreeNumber = 3;
            for (let i = 3; i <= 4; i++) {
                if (this.typesForNumber[i].length > 0) {
                    firstFreeNumber = i;
                    break
                }
            }

            this.accomodations.push({
                askedAmount: 0,
                reservedAmount: 0,
                type: {
                    persons: firstFreeNumber,
                    //type: this.typesForNumber[firstFreeNumber][0].key
                    type: this.emptyType
                }
            });
            // Entering Edit mode for the newly created element
            this.editIndex = this.accomodations.length - 1;
        },


        removeAccomodation: function (accomodation) {
            if (confirm('Bist du sicher, dass du das Kabine entfernen willst')) {
                let index = this.accomodations.findIndex(x => x == accomodation);
                this.accomodations.splice(index, 1);
            }
        }
    }
}
</script>
