<template>
    <modal
        v-bind:classes  = "'placeholder'"
        v-bind:visible  = "isModalVisible"
        v-on:closeModal = "closeModal"
    >
        <template v-if="modalLoading">
            <div slot="content">
                <loading textInFront="Daten werden aktualisiert"></loading>
            </div>
        </template>

        <template v-else>
            <template slot="header">
                <div class="is-saving">
                    <i class="material-icons" v-if="isSaving === 'ok'">check</i>
                    <i class="material-icons icon-spin" v-else-if="isSaving">refresh</i>
                </div>

                <div class="name">{{ localPlaceholder.startPort.place.name }} - {{ localPlaceholder.endPort.place.name }}</div>

                <div class="header-extra-info">
                    <div>
                        <i class="material-icons">directions_boat</i>
                        <div class="buttons has-addons toggle-switch has-margin-right">
                            <button
                                class="button is-large"
                                v-on:click.prevent="localPlaceholder.type = 'night'"
                                v-bind:class="{ 'is-primary': localPlaceholder.type === 'night' }"
                            >Nacht</button>
                            <button
                                class="button is-large"
                                v-on:click.prevent="localPlaceholder.type = 'day'"
                                v-bind:class="{ 'is-primary': localPlaceholder.type === 'day' }"
                            >Tag</button>
                        </div>
                    </div>
                    <div>
                        {{date}}
                    </div>
                    <div>
                        <async-select
                            api     = "ports"
                            class="is-medium"
                            ref     = "startPort"
                            v-model = "localPlaceholder.startPort"
                            v-bind:custom-label = "item => item.place.name"
                            crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                            v-bind:clearable    = "false"
                            v-bind:allow-empty  = "false"
                            placeholder         = "Abfahrtshafen"
                            style="margin-right: 10px"
                        />
                        <button
                            class        = "btn-single-toggle has-margin-right"
                            style="border:  1px solid #dddddd"
                            v-bind:class = "[endPortRestriction ? '' : 'is-active']"
                            v-on:click   = "endPortRestriction = !endPortRestriction"
                            title = "Nur bestehende Fährverbindungen suchen/alle Häfen suchen"
                        ><i class="material-icons" style="vertical-align: -2px; margin:0">timeline</i></button>

                        <async-select
                            api     = "ports"
                            class="is-small"
                            v-model = "localPlaceholder.endPort"
                            v-bind:custom-label = "item => item.place.name"
                            crudLinkTitle       = "Fügen Sie einen neuen Port hinzu"
                            v-bind:clearable    = "false"
                            v-bind:allow-empty  = "false"
                            :params="endPortRestriction && localPlaceholder.startPort ? {_groups: ['port:service_day_time'], 'endRoutes.startPort.id': localPlaceholder.startPort.id } : {_groups: ['port:service_day_time']}"
                            placeholder         = "Ankunftshafen"
                        />
                    </div>
                    <div class="is-block">
                        <div class="level mb-1">
                            <button
                                class        = "btn-single-toggle has-margin-right level-item"
                                style="min-width:20px"
                                v-bind:class = "[onlyTime ? '' : 'is-active']"
                                v-on:click   = "onlyTime = !onlyTime"
                                title = "Uhrzeit"
                                v-if="serviceDayTimes.length > 0"
                            ><i class="material-icons" style="margin: 0">access_time</i></button>
                            <button
                                class        = "has-margin-right"
                                style="min-width:20px"
                                title = "Einfschiffung"
                                v-else
                            ><i title="Einfschiffung" class="material-icons"  style="margin: 0">access_time</i></button>
                            <template v-if="onlyTime">
                                <date-time  class="has-margin-right ferry" v-model="localPlaceholder.startAt"></date-time> -
                                <date-time  class="has-margin-left ferry" v-model="localPlaceholder.endAt"></date-time>
                            </template>

                            <template v-else>

                                    <multiselect
                                        class="level-item"
                                        style="width: 150px"
                                        v-bind:options="serviceDayTimes"
                                        track-by="id"
                                        v-bind:custom-label="serviceDayTimeLabel"
                                        v-model="serviceDayTimeSelected"
                                        v-on:select="selectServiceTime"></multiselect>


                            </template>
                        </div>
                        <div v-if="onlyTime">
                            <button
                                class        = "has-margin-right"
                                title = "Einfschiffung"><i title="Einfschiffung" class="material-icons"  style="margin: 0">av_timer</i></button>
                            <date-time class="ferry" time-only v-model="checkInAt"></date-time>
                        </div>
                    </div>

                    <div>
                            <button
                                class        = "btn-single-toggle has-margin-right"
                                v-bind:class = "[localPlaceholder.hideClass ? '' : 'is-active']"
                                v-on:click   = "localPlaceholder.hideClass = !localPlaceholder.hideClass"
                                title = "Klasse auswählen"
                            ><i class="material-icons" style="vertical-align: -2px; margin-left: 2px">receipt</i></button>

                            <multiselect
                                style="width: 100px"
                                v-if="!localPlaceholder.hideClass && (options.ferryLabels.categorySelect)"
                                v-model="classCategory"
                                track-by="key"
                                label="value"
                                placeholder="Klasse"
                                v-bind:options="objectToKeyValueArray(options.ferryLabels.categorySelect)"
                            />

                            <multiselect
                                style="width: 150px"
                                v-if="localPlaceholder.hideClass"
                                v-model="hideClass"
                                :options="[{key: true, value: 'ohne Klasse'}, {key: false, value: 'Klasse festlegen'}]"
                                track-by="key"
                                label="value"
                                placeholder="Klasse"
                            />
                    </div>
                    <div class="is-block">
                        <div class="level mb-1">
                            <i title="Set as Additional" class="material-icons">shopping_cart</i>
                            <div class="select">
                                <select v-model="localPlaceholder.clientOffer.status">
                                    <option value="included">inkludiert</option>
                                    <option value="optional">optional</option>
                                    <option value="additional">gesondert</option>
                                    <option value="atLocation">vor Ort</option>
                                </select>
                            </div>
                        </div>
                        <div class="level mb-1">
                            <i class="material-icons">directions_bus</i>
                            <toggle-switch v-model="localPlaceholder.driverNotParticipating">
                                <option :value="false">mit</option>
                                <option :value="true">ohne Fahrer</option>
                            </toggle-switch>
                        </div>
                    </div>
                    <div>
                        <i title="Set as Additional" class="material-icons">directions_bus</i>
                        <multiselect
                            v-bind:options="offerTypes"
                            v-model="transportServices"
                            track-by="key"
                            label="label"
                            multiple
                        >
                            <template slot="selection" slot-scope="{ values, search, isOpen }">
                                <span class="multiselect__single" v-if="values.length && values.length > 2 && !isOpen">{{ values.length }} Optionen ausgewählt</span>
                            </template>
                        </multiselect>
                    </div>
                </div>

                <button class="button is-large is-success is-add-new" v-on:click="addNewRequest"><i class="fa fa-plus-circle" aria-hidden="true"></i> Leistungsträger hinzufügen</button>
            </template>


            <div slot="content">
                <request
                    v-for="(request, index) in localPlaceholder.requests"
                    v-bind:key="request.id"
                    v-if="!isNewRequestVisible && request.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[index]"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-bind:calculationRequest="calculationRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                    v-on:saved="handleRequestSaved"
                ></request>


                <new-request
                    v-if="isNewRequestVisible"
                    v-bind:placeholderID="localPlaceholder.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[localPlaceholder.requests.length - 1]"
                    v-on:close="closeNewRequestModal"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-on:saved="handleRequestSaved"
                ></new-request>
            </div>
        </template>
    </modal>
</template>



<script>
import pick from 'lodash/pick';
import mixins from './mixins';
import Request    from './requests/Ferry';
import NewRequest from './newRequests/Ferry';
import moment from "moment";
import {fullYear, objectToKeyValueArray} from "@utilities/functions";
import ToggleSwitch from "@components/form/ToggleSwitch.vue";


export default {
    mixins: [mixins],


    computed: {
        options: function () {
            return this.$store.state.options;
        },
        hideClass: {
            get: function () {
                return !this.localPlaceholder.hideClass ? {key: false, value: 'Klasse festlegen'} : {key: true, value: 'ohne Klasse'};
            },
            set: function (value) {
                this.localPlaceholder.hideClass = value.key;
            }
        },
        classCategory: {
            get: function () {
                return objectToKeyValueArray(this.options.ferryLabels.categorySelect).find(label => label.key === this.localPlaceholder.classCategory);

            },
            set: function (value) {
                if (value && value.key) {
                    this.localPlaceholder.classCategory = value.key;
                }
            }
        },
        transportServices: {
            get: function () {
                if (!!this.offerTypes){
                    return this.offerTypes
                        .filter(({key}) => this.localPlaceholder[key] === true)
                } else {
                    return []
                }
            },
            set: function (value) {
                this.offerTypes.map(item => {
                    if (Object.values(value).indexOf(item) > -1) {
                        this.localPlaceholder[item.key] = true;
                    } else {
                        this.localPlaceholder[item.key] = false;
                    }
                });
            },
        },
        checkInAt: {
            get: function () {
                return moment(this.localPlaceholder.checkInAt, 'DD.MM.YYYY HH:mm:ss').format('HH:mm:ss');
            },
            set: function (value) {
                this.localPlaceholder.checkInAt = moment(this.localPlaceholder.startAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + value;
            }
        },

        date: function () {
            if(this.localPlaceholder.type === 'night') {
                return fullYear(this.localPlaceholder.startAt) + ' - ' + fullYear(this.localPlaceholder.endAt);
            } else if (this.localPlaceholder.type === 'day') {
                return fullYear(this.localPlaceholder.startAt);
            }
        },

        today: function () {
            let date = new Date();
            date.setHours(0, 0, 0, 0);
        },


        emptyRequest: function () {
            return {
                agency:      null,
                startAt:     this.localPlaceholder.startAt,
                endAt:       this.localPlaceholder.endAt,
                startPort:   this.localPlaceholder.startPort,
                endPort:     this.localPlaceholder.endPort,
                embarkAt:    this.localPlaceholder.checkInAt,
                shipName:    null,
                ferry:       null,
                placeholder: '/api/ferry_placeholders/' + this.localPlaceholder.id,
                contingent: {
                    doubleCabins: [
                        {
                            askedAmount: 0,
                            type: {
                                persons: 2,
                                type: 'inside'
                            }
                        },
                        {
                            askedAmount: 0,
                            type: {
                                persons: 2,
                                type: 'outside'
                            }
                        }
                    ],
                    singleCabins: [
                        {
                            askedAmount: 0,
                            type: {
                                persons: 1,
                                type: 'inside'
                            }
                        },
                        {
                            askedAmount: 0,
                            type: {
                                persons: 1,
                                type: 'outside'
                            }
                        }
                    ],
                    otherCabins:  [],
                    ticketsAsked: 0,
                    ticketsReserved: 0,
                    ticketsExtraAsked: null,
                    ticketsExtraDescription: null,
                    ticketsExtraReserved: null
                },
                type:        this.localPlaceholder.type,
                optionNr:    null,
                optionTodo: {dueDate: null},
                itemPrices:  []
            }
        },


        placeholderInfo: function () { return pick(this.localPlaceholder, 'startPort', 'endPort', 'type'); },
        orderContacts:   function () { return this.$store.state.order.orderContacts.filter(contact => contact.role === 'bus_driver'); }
    },


    data: function () {
        return {
            typeOfPlaceholder: 'ferry',
            offerTypes: [
                {
                    key: 'offerBus',
                    label: 'Bus'
                }, {
                    key: 'offerSprinter',
                    label: 'Sprinter'
                }, {
                    key: 'offerTrailer',
                    label: 'Anhänger'
                }, {
                    key: 'offerCar',
                    label: 'PKW'
                }, {
                    key: 'offerMotorcycle',
                    label: 'Motorrad'
                }
            ],
            onlyTime: true,
            apiEndpoint: 'ferry_placeholders',
            endPortRestriction: true,
            oldValue: null,
            serviceDayTimes: [],
            serviceDayTimeSelected: null,
        }
    },

    methods: {
        objectToKeyValueArray,
        updateChecbox: function (property, value) {
            let dataToBeModified = {};
            dataToBeModified[property] = value;
            this.localPlaceholder[property] = value;

            this.editPlaceholder({
                type:    this.typeOfPlaceholder + '_placeholders',
                id:      this.localPlaceholder.id,
                data:    dataToBeModified,
                params: '?_groups[]=order_offer_write'
            });
        },
        selectServiceTime: function (selectedTime) {
            //setTime
            if(selectedTime){
                const startAt = selectedTime.startTimeOfDay ? selectedTime.startTimeOfDay : '00:00:00';
                const endAt = selectedTime.endTimeOfDay ? selectedTime.endTimeOfDay : '00:00:00';
                const checkInAt = selectedTime.checkInTimeOfDay ? selectedTime.checkInTimeOfDay : '00:00:00';
                this.localPlaceholder.startAt = moment(this.localPlaceholder.startAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + startAt;
                this.localPlaceholder.endAt = moment(this.localPlaceholder.endAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + endAt;
                this.localPlaceholder.checkInAt = moment(this.localPlaceholder.startAt, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + ' ' + checkInAt;
                this.onlyTime = true;
            }
        },
        serviceDayTimeLabel: function (serviceDayTime) {
            let customLabel = serviceDayTime.startTimeOfDay.substring(0, serviceDayTime.startTimeOfDay.length - 3);
            customLabel += serviceDayTime.endTimeOfDay ? ' - ' + serviceDayTime.endTimeOfDay.substring(0, serviceDayTime.endTimeOfDay.length - 3) : '';
            customLabel += serviceDayTime.checkInTimeOfDay ? ' (Einschiffung: ' + serviceDayTime.checkInTimeOfDay.substring(0, serviceDayTime.checkInTimeOfDay.length - 3) + ')' : '';
            customLabel += ' - ' + serviceDayTime.provider;
            return customLabel;
        },
    },
    watch : {
        'localPlaceholder': {
            deep: true,
            handler: function (value) {
                value=_.pick(value, 'id', 'type', 'hideClass', 'startPort', 'endPort', 'startAt', 'endAt', 'checkInAt', 'clientOffer.status', 'offerBus', 'offerSprinter', 'offerTrailer', 'offerCar', 'offerMotorcycle', 'classCategory', 'driverNotParticipating' )
                if (this.oldValue && JSON.stringify(this.oldValue) !== JSON.stringify(value) ) {
                    let reload = this.oldValue.driverNotParticipating !== value.driverNotParticipating;
                    this.savePlaceholder(value, reload)
                }
                this.oldValue= JSON.parse(JSON.stringify(value))
            }
        },
        'localPlaceholder.endPort': function() {
            if(this.localPlaceholder.endPort && this.localPlaceholder.startPort){
                if(this.localPlaceholder.endPort.allServiceDayTimes && this.localPlaceholder.endPort.allServiceDayTimes[this.localPlaceholder.startPort.id]){
                    this.serviceDayTimes = this.localPlaceholder.endPort.allServiceDayTimes[this.localPlaceholder.startPort.id];
                    this.onlyTime = false;
                } else {
                    this.serviceDayTimes = [];
                    this.onlyTime = true;
                }
            }
        },
        'localPlaceholder.startPort': function() {
            if(this.localPlaceholder.endPort && this.localPlaceholder.startPort){
                if(this.localPlaceholder.endPort.allServiceDayTimes && this.localPlaceholder.endPort.allServiceDayTimes[this.localPlaceholder.startPort.id]){
                    this.serviceDayTimes = this.localPlaceholder.endPort.allServiceDayTimes[this.localPlaceholder.startPort.id];
                    this.onlyTime = false;
                } else {
                    this.serviceDayTimes = [];
                    this.onlyTime = true;
                }
            }
        },
    },

    components: {
        ToggleSwitch,
        NewRequest,
        Request
    }
}
</script>
