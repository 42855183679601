<template>
    <div>
        <div class="bp-box is-info">
            <header><i class="material-icons">info</i> Informationen zur Anfrage</header>

            <main>
                <div class="is-one-row" v-if="!request.train || editMode">
                    <i class="material-icons">business</i> Zug:

                    <div>
                        <train-select
                            v-model                = "request.train"
                            v-bind:placeholderInfo = "localPlaceholderInfo"
                            v-bind:nullable        = "false"
                            v-bind:autoSelect      = "false"
                        ></train-select>
                    </div>

                    <agency-select
                        v-if="editMode"
                        :value          = "request.agency"
                        v-bind:provider = "null"
                        text            = "Agentur"
                        v-on:update     = "agency => request.agency = agency"
                        prefill
                    ></agency-select>
                </div>
                <div class="is-one-row">
                    <div>
                        <i class="material-icons">import_contacts</i> Status:
                        <div class="select">
                            <select v-model="request.requestStatus.status">
                                <template v-for="status in requestStatuses">
                                    <option v-bind:value="status.key">{{ status.value }}</option>
                                </template>
                            </select>
                        </div>
                    </div>

                    <div>
                        <i class="material-icons">notifications_none</i> Termine:
                        <multiselect
                            v-bind:searchable="false"
                            class="is-shorter"
                            v-if="request.todos && showSelectList"
                            style="min-width: 180px"
                            v-model="selectedTodo"
                            :options="request.todos"
                            v-bind:custom-label="todoLabel"
                            track-by="id"
                        />
                        <input
                            type="text"
                            class="input"
                            v-if="showTodoInput"
                            v-model="selectedTodo.name"
                        />
                        <datepicker
                            v-if="selectedTodo"
                            v-bind:inputClass="['is-date']"
                            v-bind:config="{
                            dateFormat: 'd.m.Y H:i:S',
                            wrap: true,
                            parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY HH:mm:ss').toDate(); },
                            formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY HH:mm:ss'); },
                            allowInput: true,
                            altInput: true,
                        }"
                            v-model="deadline"
                            placeholder="Deadline"
                        />
                        <button @click="addTodo">
                            <i class="material-icons">add</i>
                        </button>
                        <button v-if="request.todos.length > 1" @click="deleteTodoInput(request.optionTodo)">
                            <i class="material-icons">delete</i>
                        </button>
                    </div>
                    <div>
                        <i class="material-icons">format_list_numbered</i> Resa.-Nr:
                        <input type="text" class="input" v-model="request.optionNr" />
                    </div>
                    <div>
                        <i class="material-icons">assignment</i> Zugnummer:
                        <input type="text" class="input" v-model="request.trainNumber" />
                    </div>
                    <div class="has-time">
                        <i class="material-icons">format_list_numbered</i> Gleis Abfahrt:
                        <input type="text" class="input" v-model="request.startTrack" />
                    </div>

                    <div class="has-time">
                        <i class="material-icons">format_list_numbered</i> Gleis Ankunft:
                        <input type="text" class="input" v-model="request.endTrack" />
                    </div>
                </div>



                <div class="is-last-row" >
                    <div>
                        <i class="material-icons">contact_phone</i> Ansprechpartner:
                        <multiselect
                            class="is-shorter"
                            style="min-width: 180px"
                            v-model="request.requestContact"
                            v-bind:options="this.request.agency ? this.agencyContactOptions : this.providerContactOptions"
                            track-by="id"
                            :custom-label="contact => `${contact.fullName}`"
                            :to-be-copied="false"
                        >
                        </multiselect>
                    </div>
                    <template v-if="showAssignedContactAddress">
                        <div>
                            <i class="material-icons">list</i> Für Kunde sichtbar:
                            <button
                                title="Adresse anzeigen"
                                class="button is-icon-button"
                                :class="{'is-active': request.visibleContactDetails.includes('address')}"
                                @click="setVisibleContactDetails('address')"
                            ><i class="material-icons">home</i></button>
                        </div>

                        <div v-if="request.visibleContactDetails.includes('address')">
                            <i class="material-icons">home</i> Adresse
                            <multiselect
                                class="is-shorter"
                                style="min-width: 150px"
                                v-model="request.assignedAddress"
                                v-bind:options="providerAddressOptions"
                                track-by="id"
                                :custom-label="address => (address.title ? address.title + ': ' + address.streetAddress : address.streetAddress).substring(0,50)"
                                :to-be-copied="false"
                                placeholder="Keine Adresse"

                            >
                            </multiselect>
                        </div>
                        <div v-if="request.visibleContactDetails.includes('address')">
                            <i class="material-icons">list</i> Kontaktdetails sichtbar:
                            <button
                                title="Festnetz anzeigen"
                                class="button is-icon-button"
                                :class="{'is-active': request.visibleContactDetails.includes('phoneFixed')}"
                                @click="setVisibleContactDetails('phoneFixed')"
                            ><i class="material-icons">phone</i></button>
                            <button
                                title="Mobile anzeigen"
                                class="button is-icon-button"
                                :class="{'is-active': request.visibleContactDetails.includes('phoneMobile')}"
                                @click="setVisibleContactDetails('phoneMobile')"
                            ><i class="material-icons">smartphone</i></button>
                        </div>
                    </template>
                </div>
            </main>
        </div>
        <div>
            <toggle-switch v-model="hasConnectedContingent">
                <option :value="true"><i class="material-icons mr-2">link</i> Mit Kundenkontingent verknüpfen</option>
                <option :value="false"><i class="material-icons mr-2">link_off</i> Individuelles Kontingent eingeben</option>
            </toggle-switch>
        </div>
        <br>

        <div class="columns">

                <div class="column" v-if="placeholderInfo.type === 'night'">
                    <simple-train-contingent
                        v-if="request.contingent"
                        v-model="request.contingent.doubleCabins"
                        type="train"
                        :category="placeholderInfo.category"
                        :day="placeholderInfo.type === 'day'"
                        v-bind:nrOfPersons="2"
                        v-bind:types="trainTypes"
                        v-bind:isInPackage="request.package ? true : false"
                        v-bind:hasConnectedContingent="request.hasConnectedContingent"
                    ></simple-train-contingent>
                </div>

                <div class="column">
                    <simple-train-contingent
                        v-if="request.contingent"
                        v-model="request.contingent.singleCabins"
                        type="train"
                        :category="placeholderInfo.category"
                        :day="placeholderInfo.type === 'day'"
                        v-bind:nrOfPersons="1"
                        v-bind:types="trainTypes"
                        v-bind:isInPackage="request.package ? true : false"
                        v-bind:hasConnectedContingent="request.hasConnectedContingent"
                    ></simple-train-contingent>
                </div>

                <div class="column" v-if="placeholderInfo.type === 'night'">
                    <train-contingent
                        v-if="request.contingent"
                        v-model="request.contingent.otherCabins"
                        :category="placeholderInfo.category"
                        type="train"
                        v-bind:types="trainTypes"
                        v-bind:isInPackage="request.package ? true : false"
                        v-bind:hasConnectedContingent="request.hasConnectedContingent"
                    ></train-contingent>
                </div>


        </div>


        <div class="columns">
            <div class="column is-one-third">
                <tip-tap v-model="request.notes" />
            </div>
            <div class="column">
                <item-prices
                    v-if="request.package"
                    :is-package="true"
                    v-bind:isWithoutPrice="false"
                    v-model="request.package.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
                <item-prices
                    v-else
                    v-bind:isWithoutPrice="false"
                    v-model="request.itemPrices"
                    v-bind:provider="provider"
                ></item-prices>
            </div>
        </div>


        <p class="explanation">
            <i class="material-icons">check_circle</i> = bestätigtes Kontingent
            <i class="material-icons">help</i> = angefragtes Kontingent
            <i class="material-icons">person_add</i> = Extrazimmer für Busfahrer, Reiseleiter, etc.
        </p>
    </div>
</template>



<script>
import { fullYear } from '@utilities/functions';
import AsyncSelect  from '@components/form/AsyncSelect';
import TrainContingent       from '../components/contingent/Train';
import SimpleTrainContingent from '../components/contingent/SimpleTrain';
import mixins                from './mixins';
import TrainSelect from "@orders/placeholders/components/forms/TrainSelect";


export default {
    mixins: [mixins],


    props: {
        placeholderInfo: { type: Object, required: true }
    },


    data: function () {
        return {
            hasExtra: false
        }
    },


    computed: {
        localPlaceholderInfo: function () {
            return {
                startTrainStation: this.request.startTrainStation,
                endPort:   this.request.endTrainStation,
                type:      this.placeholderInfo.type,
                trainType:      this.placeholderInfo.trainType
            };
        },


        trainTypes: function () { return this.$store.state.optionsTrainCabinTypes; },
        provider:   function () { return this.request.train },
        hasConnectedContingent: {
            get: function() {
                return this.request.hasConnectedContingent ?? false
            },
            set: function(val) {
                this.request.hasConnectedContingent = val;

            },
        }
    },


    methods: {



        /*arrangeCabins: function (array) {
            // moving 'outside' first (for the case we have more than 2 items)
            let localArray = [array.filter(x => x.type.type === 'outside')[0], ...array.filter(x => x.type.type !== 'outside')];

            // moving 'inside' first
            return [localArray.filter(x => x.type.type === 'inside')[0], ...localArray.filter(x => x.type.type !== 'inside')]
        },*/

    },


    created: function () {
    },


    watch: {

    },


    components: {
        AsyncSelect,
        TrainContingent,
        SimpleTrainContingent,
        TrainSelect
    }
}
</script>
