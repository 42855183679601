import { Multiselect }     from '@components/form';
import { getValueWithKey } from '@utilities/functions';


export default {
    props: {
        value:              { type: Array,  required: true },
        type:               { type: String, required: true },
        types:              { type: Array,  required: true },

        isOnlyAsked:        { type: Boolean, default: false },
        isInPackage:        { type: Boolean, default: false },
        hasConnectedContingent: {type: Boolean, default: false}
    },


    computed: {
        totalAsk: function () {
            let total = 0;
            for (let i = 0, len = this.accomodations.length; i < len; i++) {
                total += this.accomodations[i].askedAmount;
            }

            return total;
        },


        totalReserved: function () {
            let total = 0;
            for (let i = 0, len = this.accomodations.length; i < len; i++) {
                total += this.accomodations[i].reservedAmount;
            }

            return total;
        }
    },


    data: function () {
        return {
            accomodations: JSON.parse(JSON.stringify(this.value)),
            emptyType:     'empty',

            // Which element is being edited
            editIndex:     -1
        };
    },


    methods: {
        accomodationTypeSelected: function (accomodation) {
            // Exiting edit mode
            this.editIndex = -1;
            // Adding the accomodation Type option
            accomodation.type.type = accomodation.type.type.key;
        },


        addTag: function (newTag, accomodation) {
            // Exiting edit mode
            this.editIndex = -1;
            accomodation.type.type = newTag.substring(0, 60);
        },


        closeMultiselect: function (value) {
            if(value !== 'standard' && value !== 'inside' && value !== 'empty') {
                this.editIndex = -1;
            }
        },


        setValues: function (accomodation) {
            accomodation.reservedAmount += accomodation.askedAmount;
            accomodation.askedAmount    = 0;
        },


        getValueWithKey
    },


    watch: {
        accomodations: {
            handler: function () {
                // Fixing empty strins to 0 (zero)
                for (let i = 0, len = this.accomodations.length; i < len; i++) {
                    if (this.accomodations[i].askedAmount === '') {
                        this.accomodations[i].askedAmount = 0;
                    }
                    if (this.accomodations[i].reservedAmount === '') {
                        this.accomodations[i].reservedAmount = 0;
                    }
                    if (this.accomodations[i].extraAskedAmount === '') {
                        this.accomodations[i].extraAskedAmount = 0;
                    }
                    if (this.accomodations[i].extraReservedAmount === '') {
                        this.accomodations[i].extraReservedAmount = 0;
                    }
                }

                this.$emit('input', this.accomodations);
            },
            deep: true
        },

        value: {
            deep: true,

            handler: function(value) {
                const accomodations = JSON.parse(JSON.stringify(this.accomodations))
                    .map(({id, contingent, ...item}) => ({
                        ...item,
                        ...!!item.type && {
                            type: {
                                persons: item.type.persons,
                                type: item.type.type
                            }
                        }
                    }))

                const newValue = JSON.parse(JSON.stringify(value))
                    .map(({id, contingent, ...item}) => ({
                        ...item,
                        ...!!item.type && {
                            type: {
                                persons: item.type.persons,
                                type: item.type.type
                            }
                        }
                    }))

                if (JSON.stringify(accomodations) !== JSON.stringify(newValue)) {
                    this.accomodations = JSON.parse(JSON.stringify(value))
                }
            }
        }
    },


    components: {
        Multiselect
    }
};
