<template>
    <loading v-if="isLoading"></loading>

    <div class="is-empty-cost" v-else-if="isEmpty">
        Berechnung ohne Preise nicht möglich
    </div>

    <div v-else>
        <div class="redesign-table is-costs">
            <div class="row is-head">
                <div class="cell">Leistung</div>
                <div class="cell"><template v-if="!packageID">Übernachtungen</template></div>
                <div class="cell">Anzahl</div>
                <div class="cell">Einzelpreis</div>
                <div class="cell">Gesamtpreis</div>
            </div>


            <package
                v-if="packageID"
                v-bind:calculations="calculations"
                v-bind:roomTypes="roomTypes"
                v-bind:cabinTypes="cabinTypes"
                v-bind:trainCabinTypes="trainCabinTypes"
                v-bind:isLoading="isLoading"
            ></package>




            <div class="is-zebra" v-else>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="room.id"
                >
                    <div class="cell"><span :style="isStandardRoom(room) ? 'font-weight: bold' : ''">{{ roomLabel(room) }}</span></div>
                    <div class="cell">{{ calculations.durationInDays }}</div>
                    <div class="cell">{{ roomPersons(room) }}</div>
                    <div class="cell">{{ roomPrice(room) | priceView }}</div>
                    <div class="cell"><b>{{ roomTotal(room) | priceView }}</b></div>
                </div>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="'extra-' + room.id"
                    v-if="room.extraReservedAmount"
                >
                    <div class="cell">Extra - {{ getAccommodationLabel(room.type.type, room.type.persons, roomTypes) }} - {{ room.extraDescription }}</div>
                    <div class="cell">{{ calculations.durationInDays }}</div>
                    <div class="cell">{{ room.extraReservedAmount * room.type.persons }}</div>
                    <div class="cell">{{ room.absolutePrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ room.totalPriceExtraReserved.amount | priceView }}</b></div>
                </div>

                <div
                    class="row"
                    v-for="room in sortedRooms"
                    v-bind:key="'free-' + room.id"
                    v-if="room.personsFreePlaces"
                >
                    <div class="cell">{{freePlaceLabel(room)}}</div>
                    <div class="cell">{{ calculations.durationInDays }}</div>
                    <div class="cell">{{ room.personsFreePlaces }}</div>
                    <div class="cell">{{ -1 * room.absolutePrice.amount | priceView }}</div>
                    <div class="cell"><b>{{ -1 * room.totalPriceFreePlaces.amount | priceView }}</b></div>
                </div>




                <!-- ================ Item Prices ================  -->
                <div
                    class="row"
                    v-for="item in calculations.itemPrices"
                    v-bind:key="'item-' + item.id"
                    v-if="item.billingType !== 'info' && item.limitIsValid"
                >
                    <div class="cell">Zuschlag: {{ item.name }}</div>
                    <div class="cell">{{ item.quantityType === 'per_person_night' || item.quantityType === 'per_single_room_night' ? calculations.durationInDays : ''}}</div>
                    <div class="cell">{{ item.calculatedQuantityWithExtra}}</div>
                    <div class="cell">{{ item.price.amount | priceView }}</div>
                    <div class="cell"><b>{{ item.totalPrice.amount | priceView }}</b></div>
                </div>

                <loading v-if="isLoading"></loading>
            </div>
        </div>

        <div class="for-costs-total">
            <div><b>TOTAL ({{calculations.totalPrice.currency}})</b></div>
            <div><b>{{ calculations.totalPrice.amount | priceView }}</b></div>
        </div>
    </div>
</template>



<script>
import mixins from './mixins';
import {compareDates, fullYear} from "@utilities/functions";


export default {
    mixins: [mixins],


    data: function () {
        return {
            apiEndpoint: 'hotel_requests/'
        }
    },

    computed: {
        sortedRooms: function () {
            let roomTypes = this.roomTypes;
            return this.calculations.contingent.all.sort(function (a, b){
                if(!roomTypes){
                    return 0;
                }
                const indexA = roomTypes.findIndex(item => item.persons === a.type.persons && item.type === a.type.type);
                const indexB = roomTypes.findIndex(item => item.persons === b.type.persons && item.type === b.type.type);
                return indexA - indexB;
            });
        },

        standardRoom: function () {
            return this.sortedRooms.find(room => this.isStandardRoom(room));
        }
    },

    methods: {
        isStandardRoom: function (room) {
            return room.type.type === 'standard' && room.type.persons === 2;
        },

        isNegative: function(room) {
              return this.supplementPrices ? room.premiumPrice.amount < 0 : room.absolutePrice.amount < 0;
        },

        roomLabel: function (room) {
            return this.isStandardRoom(room) ?
                this.calculations.info.title + ' - Preis pro Person - ' + this.getAccommodationLabel('standard', 2, this.roomTypes)
                : this.getAccommodationLabel(room.type.type, room.type.persons, this.roomTypes)
        },

        freePlaceLabel: function (room) {
            let addition =  this.isStandardRoom(room) ? 'halben ' : '';
            return 'Freiplatz im ' + addition + this.getAccommodationLabel(room.type.type, room.type.persons, this.roomTypes);
        },

        roomPersons: function (room) {
            return this.supplementPrices && this.isStandardRoom(room) ? this.calculations.personsReserved : room.reservedAmount * room.type.persons;
        },

        roomPrice: function (room) {
            return !this.supplementPrices || this.isStandardRoom(room) ? room.absolutePrice.amount :  room.premiumPrice.amount;
        },

        roomTotal: function (room) {
            if(this.isStandardRoom(room) && this.supplementPrices) {
                return room.totalPriceReservedStandard.amount * this.calculations.durationInDays;
            }
            return !this.supplementPrices ? room.totalPriceReserved.amount * this.calculations.durationInDays : room.totalPriceReservedPremium.amount
        },
    },

}
</script>
