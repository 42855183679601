var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bp-box is-freeplaces is-flex" }, [
    _c("header", [
      _c("div", [
        _c("i", { staticClass: "material-icons" }, [_vm._v("no_sim")]),
        _vm._v(" Freiplätze\n            "),
        _c("button", { on: { click: _vm.addFreePlace } }, [
          _c("i", {
            staticClass: "fa fa-plus-circle",
            attrs: { "aria-hidden": "true" },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "main",
      { staticClass: "flex-grow" },
      [
        _vm._l(_vm.localFreePlaces, function (freePlace, index) {
          return _c("div", { key: freePlace.id }, [
            _c("div", [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("format_list_numbered"),
              ]),
              _vm._v(
                "\n                " +
                  _vm._s(index + 1) +
                  ". Freiplatz ab\n                "
              ),
              _c("input", {
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: freePlace.numberOfPersons },
                on: {
                  blur: function ($event) {
                    freePlace.numberOfPersons = _vm.toNumber(
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [_vm._v("Pax")]),
            _vm._v(" "),
            _c("div", [
              _c("i", { staticClass: "material-icons" }, [
                _vm._v("room_service"),
              ]),
              _vm._v("\n                Typ:\n                "),
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: freePlace.type,
                        expression: "freePlace.type",
                      },
                    ],
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          freePlace,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                    },
                  },
                  [
                    _vm.hasHotel || _vm.hasFerry
                      ? _c("option", { attrs: { value: "double" } }, [
                          _vm._v("Doppel"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasHotel || _vm.hasFerry
                      ? _c("option", { attrs: { value: "single" } }, [
                          _vm._v("Einzel"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.hasHotel && !_vm.hasFerry
                      ? _c("option", { attrs: { value: "other" } }, [
                          _vm._v("Zusatzleistung"),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn-delete",
                  attrs: { title: "Löschen" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteFreePlace(index)
                    },
                  },
                },
                [_c("i", { staticClass: "material-icons" }, [_vm._v("delete")])]
              ),
            ]),
          ])
        }),
        _vm._v(" "),
        _vm.localFreePlaces.length === 0
          ? _c("div", [_vm._v("Es wurden keine Freipläze vermerkt")])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }