<template>
    <div class="bp-panel is-form-contingent">
        <div class="panel-label">Hotel - {{ nrOfPersons === 1 ? 'EZ' : 'DZ' }}</div>
        <header>
            <div>{{ nrOfPersons }} x <i class="material-icons">local_hotel</i></div>

            <div><template v-if="!isOnlyAsked"><i class="material-icons">check_circle</i></template></div>
            <div><i class="material-icons">help</i></div>

            <div>
                <button class="has-margin-top" v-if="!isInPackage" title="Extrazimmer für Reisebegleitung" v-on:click="addExtraPeople"><i class="material-icons">person_add</i></button>
                <button title="Fügen Sie einen neuen Zimmertyp hinzu" v-on:click="addAccomodationType"><i aria-hidden="true" class="fa fa-plus-circle"></i></button>
            </div>
        </header>


        <main v-bind:class="{ 'has-extra': hasExtra && !isInPackage }">
            <div class="row" v-for="(accomodation, index) in accomodations" v-bind:key="accomodation.id">
                <div v-if="editIndex !== index">
                    <div>
                        <template v-if="nrOfPersons === 1">Einzelzimmer - </template>
                        <template v-else>Doppelzimmer - </template>
                        {{ getValueWithKey({ key: accomodation.type.type , optionList: types }) }}
                    </div>

                    <div>
                        <template v-if="index > 0"><!-- The standard can't be removed -->
                            <button class="btn-delete" title="Löschen" v-on:click="removeAccomodation(accomodation)" v-if="accomodations.length > 1"><i class="material-icons">delete</i></button>
                        </template>

                        <button class="btn-edit" title="Editieren" v-on:click="editIndex = index" v-if="index !== 0"><i class="fa fa-pencil"></i></button>
                    </div>
                </div>

                <div class="multiselect-is-small" v-else>
                    <multiselect
                        v-model="accomodation.type.type"
                        v-bind:options="possibleTypes"
                        v-bind:taggable="false"
                        placeholder="Zimmertyp"
                        label="value"
                        track-by="key"
                        tag-placeholder="Add this as new tag"
                        select-label=""
                        deselect-label="Löschen"
                        v-on:input="accomodationTypeSelected(accomodation)"
                        v-on:tag="newTag => { addTag(newTag, accomodation); }"
                        v-on:close="closeMultiselect"
                    ></multiselect>
                </div>

                <div>
                    <input
                         v-if="!isOnlyAsked"
                        class="input"
                        type="text"
                        v-model.number="accomodation.reservedAmount"
                        v-on:focus="$event.target.select()"
                         v-bind:disabled="hasConnectedContingent"
                    />
                </div>

                <div>
                    <input
                        class="input"
                        type="text"
                        v-model.number="accomodation.askedAmount"
                        v-on:focus="$event.target.select()"
                        v-bind:disabled="hasConnectedContingent"
                    />
                </div>


                <div><button
                    v-if="!isOnlyAsked"
                    class="button"
                    title="Copy value"
                    v-on:click="setValues(accomodation)"
                >
                    <i class="material-icons">check_circle</i>
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <i class="material-icons">help</i>
                </button></div>
            </div>


            <div class="row extra-people has-border-top" v-if="hasExtra && !isInPackage">
                <div>
                    <i class="material-icons">person_add</i>
                    <input
                        class="input"
                        type="text"
                        v-model="accomodations[standardIndex].extraDescription"
                    />
                    <button class="btn-delete" title="Löschen" v-on:click="deleteExtra"><i class="material-icons">delete</i></button>
                </div>

                <div>
                    <input
                        v-if="!isOnlyAsked"
                        class="input"
                        type="text"
                        v-model.number="accomodations[standardIndex].extraReservedAmount"
                        v-on:focus="$event.target.select()"
                    />
                </div>
                <div>
                    <input
                        class="input"
                        type="text"
                        v-model.number="accomodations[standardIndex].extraAskedAmount"
                        v-on:focus="$event.target.select()"
                    />
                </div>


                <div><button
                    v-if="!isOnlyAsked"
                    class="button"
                    title="Copy value"
                    v-on:click="setExtraValues"
                >
                    <i class="material-icons">check_circle</i>
                    <i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    <i class="material-icons">help</i>
                </button></div>
            </div>


            <div class="row has-border-top">
                <div>
                    <b>
                        <template v-if="nrOfPersons === 1">Einzelzimmer - </template>
                        <template v-else>Doppelzimmer - </template>
                        Gesamt
                    </b>
                </div>

                <div><span v-if="!isOnlyAsked">{{ totalReserved }} ({{ accomodations[standardIndex].extraReservedAmount }})</span></div>
                <div><span>{{ totalAsk }} <template v-if="accomodations[standardIndex].extraAskedAmount">({{ accomodations[standardIndex].extraAskedAmount }})</template></span></div>
                <div></div>
            </div>
        </main>
    </div>
</template>



<script>
import mixins from './mixins.js';
import { notifySuccess, notifyError } from '@components/Notification';


let emptyType = 'standard';


export default {
    mixins: [mixins],


    props: {
        nrOfPersons: { type: Number, required: true },
        hasConnectedContingent: {type: Boolean, default: false}
    },


    data: function () {
        return {
            // For extra persons
            hasExtra: false
        }
    },


    computed: {
        standardIndex: function () { return this.accomodations.findIndex(x => x.type.type === 'standard'); },


        possibleTypes: function () {
            let listOfTypes = JSON.parse(JSON.stringify(this.types));

            // Loop through accomodations and remove options already used
            for (let i = 0, len = this.accomodations.length; i < len; i++) {
                if (this.editIndex !== i && this.accomodations[i].type.type) {
                    listOfTypes = listOfTypes.filter(x => x.key != this.accomodations[i].type.type);
                }
            }

            return listOfTypes;
        }
    },


    methods: {
        addAccomodationType: function () {
            this.accomodations.push({
                askedAmount: 0,
                reservedAmount: 0,
                type: {
                    persons: this.nrOfPersons,
                    type: emptyType
                }
            });
            // Entering Edit mode for the newly created element
            this.editIndex = this.accomodations.length - 1;
        },


        removeAccomodation: function (accomodation) {
            if (confirm('Bist du sicher, dass du das Zimmer entfernen willst?')) {
                let index = this.accomodations.findIndex(x => x == accomodation);
                this.accomodations.splice(index, 1);
            }
        },


        addExtraPeople: function () {
            if (this.standardIndex !== -1) {
                this.hasExtra = true;

            } else {
                notifyError('We don\'t have any contingent');
            }
        },


        deleteExtra: function () {
            this.accomodations[this.standardIndex].extraDescription    = null;
            this.accomodations[this.standardIndex].extraReservedAmount = 0;
            this.accomodations[this.standardIndex].extraAskedAmount    = 0;
            this.hasExtra = false;
        },


        arrangeRooms: function () {
            // moving 'standard' first
            this.accomodations = [
                    this.accomodations.filter(x => x.type.type === 'standard')[0],
                    ...this.accomodations.filter(x => x.type.type !== 'standard')
                ];
        },


        setExtraValues: function () {
            this.accomodations[this.standardIndex].extraReservedAmount = this.accomodations[this.standardIndex].extraAskedAmount;
            this.accomodations[this.standardIndex].extraAskedAmount    = 0;
        }
    },


    created: function () {
        this.arrangeRooms();

        // Setting the initial state
        let extraPerson = this.accomodations[this.standardIndex];
        this.hasExtra = extraPerson && (extraPerson.extraAskedAmount || extraPerson.extraReservedAmount) ? true : false;
    },


    watch: {
        types: function () {
            this.possibleTypes = this.types;
        }
    }
}
</script>
