<template>
    <div>
        <div class="columns">
            <div class="column is-one-third">
                <div class="bp-box is-price">
                    <header>
                        <div>
                            <other-service-icons v-bind:type="request.otherServiceType.category"></other-service-icons> Zusatztleistung
                        </div>
                    </header>

                    <main>
                        <!-- Standard -->
                        <div class="is-standard calculation">
                            <div>
                                <template v-if="request.contingent.priceType === 'per_person'">
                                    <i class="material-icons">person</i> Teilnehmer
                                </template>
                                <template v-else>
                                    <i class="material-icons">people</i> Gruppenpreis
                                </template>
                            </div>

                            <input-price
                                v-model="standardPrice"
                                :provider-prices="request.contingent.providerPrices.buy_price ? request.contingent.providerPrices.buy_price : []"
                                :small-input="true"
                                :price-currency="request.requestCurrency"
                            />


                        </div>
                    </main>
                </div>
            </div>
        </div>


        <div class="columns">
            <div class="column is-one-third">
                <free-places v-model="request.freePlaces" v-bind:request="request"></free-places>
            </div>
            <div class="column">
                <item-prices v-model="request.itemPrices" v-bind:provider="provider"></item-prices>
            </div>
        </div>
        <div></div>
    </div>
</template>



<script>
import OtherServiceIcons from '@components/OtherServiceIcons';
import mixins from '../mixinsIndividually';

export default {
    mixins: [mixins],


    computed: {
        provider:  function () { return this.request.otherService },

        standardPrice: {
            get: function () {
                return this.request.contingent.price.amount
            },

            set: function (value) {
                this.request.contingent.price = {
                    amount: value,
                    currency: this.request.requestCurrency
                }
            }
        }
    },


    methods: {
        savePrice: function (price) {
            this.request.contingent.price = price;
        },
    },


    components: {
        OtherServiceIcons,
    }
}
</script>
