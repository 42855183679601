<template>
    <div>
        <div class="bp-box is-info" v-if="isHotel">
            <header>
                <i class="material-icons">restaurant</i> Menüauswahl
            </header>
            <main>
                <div class="is-one-row">
                    <div>
                        <i class="material-icons">date_range</i> Menü für den
                        <multiselect
                            class="is-shorter mr-1"
                            style="width: 100px"
                            v-model="date"
                            :options="dates"
                        />
                    </div>
                    <div>
                        <i class="material-icons">access_time</i>
                        <multiselect
                            class="is-shorter mr-1"
                            style="width: 120px"
                            v-model="subType"
                            :options="subTypeOptions"
                        />
                    </div>
<!--                    <div>
                        <i class="material-icons">restaurant</i> Aktuelle Menüwahl:
                        21.01. Schnitzel | 22.01. Dorade
                    </div>-->
                </div>


            </main>
        </div>

        <div class="columns is-multiline" v-if="!isHotel || (date && subType)">
            <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-flex" v-for="menu in menus" v-bind:key="menu.id">
                <div class="bp-panel pt-3 is-flex-grow">

                    <div class="panel-content">
                        <div class="content mb-2">
                            <label class="radio" v-if="toggleRadioButton(menu)">
                                <input  class="ml-0" type="radio" @change="check(menu)" v-model="checked" :value="menu">
                                Menü auswählen
                            </label>
                            <label  class="tag is-primary mr-2 ml-0" v-if="toggleLabel(menu)">Ausgewählt</label>
                        </div>
                        <div class="content mb-0">
                            <div class="level">
                                <strong class="level-left">{{menu.title}}</strong>
                                <div class="level-right">
                                    <span class="has-text-right" v-html="price(menu)"></span>
                                </div>
                            </div>
                        </div>
                        <div class="content mb-0">
                            <div class="level">
                                <div class="level-left is-small-text">
                                    {{dateLabel(menu)}}
                                </div>
                            </div>
                        </div>
                        <div class="nl2br content mt-2" v-html="menu.content"></div>
                    </div>
                </div>

            </div>
            <div class="column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-widescreen is-flex" key="add">
                <div class="bp-panel is-add" style="min-height: 100px" v-on:click="openForm">
                    <i class="material-icons">add</i>
                </div>
            </div>
        </div>
        <menu-form ref="form"  class="menu-form" :options-array="$store.state.options" v-on:updateMenus="updateMenus" :provider="request.provider"/>

    </div>


</template>

<script>

import {axiosByMethod, edit, getAxios, post} from "@api";
import {Multiselect} from "@components/form";
import {fullYear, getIdFromUrl, listOfDays} from '@utilities/functions';
import {notifyError, notifySuccess} from '@components/Notification';
import MenuForm from '@components/form/Menu.vue';
import store from "@orders/store";
import toNumber from "lodash/toNumber";
import moment from "moment";
export default {
    store: store,

    data () {
        return {
            menus: [],
            loaded: false,
            date: this.request.startAt,
            subType: 'Abendessen',
            checked: '',
            selectedMenuId: null,
        }
    },
    props: {
        request: {
            type: Object,
            required: true
        },
        providerId: {
          type: Number
        },
        otherServiceTypeId: {
            type: Number
        }
    },

    mounted() {
        if(!this.loaded) {
            this.getMenus();
        }
    },
    methods: {
        price: function(item) {
            let array = [];
            if(item.requestTypes.includes('other') && item.price && toNumber(item.price.amount)) {
                array.push(item.price.formatted);
            }
            if(item.requestTypes.includes('hotel') && item.supplementPrice && toNumber(item.supplementPrice.amount) ) {
                array.push('HP +' + item.supplementPrice.formatted);
            }
            return array.join('<br>');
        },
        toggleRadioButton: function(menu) {
            if (this.isHotel) {
                return menu.id != this.selectedMenuId  && this.subType !== null && this.date !== null ? true : false;
            } else {
               return this.request.otherService && this.request.otherService.menu !== '/api/menus/' + menu.id ? true : false;
            }
        },
        toggleLabel: function(menu) {
            if (this.isHotel) {
                return menu.id == this.selectedMenuId ? true : false;
            } else {
                return this.request.otherService && this.request.otherService.menu === '/api/menus/' + menu.id ? true : false;
            }
        },
        getTripDetails: function () {
            getAxios('trip_details', {
                type: 'menu',
                title: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                subType: this.subType,
                _groups: ['order_trip_detail:menu']
            }).then(response => {
                this.selectedMenuId = null;
                if (response.data.length > 0 && response.data[0].menu && response.data[0].subType === this.subType && response.data[0].title === moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY')) {
                    this.selectedMenuId = getIdFromUrl(response.data[0].menu);
                }
            })
        },
        openForm: function (data = {}) {
            this.$refs.form.open(data);
        },

        updateMenus: function() {
            this.getMenus();
        },
        getMenus: function() {
            getAxios('menus', {
                _groups: [
                    'menu:provider_other_service',
                    'other_service_write',
                    'other_service_type_read',
                    'menu:provider',
                    'menu:order_trip_detail',
                    'order_trip_detail_read'
                ],
                'provider.id' : this.providerId,
                ...!this.isHotel && {'requestTypes' : 'other' },
                ...this.isHotel && {'requestTypes' : 'hotel' },
            }).then(response => {
                this.menus = response.data;
                this.loaded = true;
                this.getTripDetails();
            })
        },
        check: function(menu) {

             if (this.request.provider) {
                 if(menu.otherService && !this.isHotel) {
                     this.request.otherService = {...menu.otherService, menu: '/api/menus/' + menu.id};
                 } else {
                     edit('hotel_requests/' + this.request.id, {
                         menu: {
                             title: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                             type: 'menu',
                             text: menu.content,
                             subType: this.subType,
                             menu: menu,
                         }
                     },
                         {
                             _groups: ['modal_write']
                         }
                     ).then(response => {
                         this.request.itemPrices = response.data.itemPrices;
                     })
                     /*let itemPrices = this.request.package ? this.request.package.itemPrices : this.request.itemPrices;
                     let name = this.subType + ' ' + moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY');
                     if(menu.supplementPrice && toNumber(menu.supplementPrice.amount)) {
                         let itemPrice = itemPrices.find(item => item.name === name);
                         if(!itemPrice) {
                             itemPrice = {
                                 name: name,
                                 quantityType: 'per_person',
                                 billingType: 'included',
                                 price: menu.supplementPrice,
                                 inCalculation: true,
                                 limitType: 'validIfLimit'
                             }
                             itemPrices.push(itemPrice);
                         } else {
                             itemPrice.price = menu.supplementPrice;
                         }
                     } else {
                         let index = itemPrices.findIndex(item => item.name === name);
                         if(index !== -1) {
                             itemPrices.splice(index, 1);
                         }
                     }*/


                     //for hotel only since you chose date | other backend takes care of creating trip detail
                    /* getAxios('trip_details', {
                         'request.id': this.request.id,
                         type: 'menu',
                         title: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                         subType: this.subType,
                     }).then(response => {
                         let data = {
                             title: moment(this.date, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                             type: 'menu',
                             text: menu.content,
                             subType: this.subType,
                             menu: '/api/menus/' + menu.id,
                             order: '/api/orders/' + this.orderID,
                             request: '/api/requests/' + this.request.id
                         };
                         axiosByMethod(response.data.length === 0 ? 'post' : 'put', response.data.length === 0 ? 'trip_details' : 'trip_details/' + response.data[0].id, {...data}, {
                             _groups: ['order_trip_detail:menu']
                         }).then(response => {
                             this.selectedMenuId = getIdFromUrl(response.data.menu);
                             this.getMenus();
                             notifySuccess("Menü zur Menüliste hinzugefügt.")
                         })
                     })*/
                 }

             }

        },
        dateLabel: function(item) {
            if (item.startAt && item.endAt) {
                return item.startAt.substring(0, 6) + ' - ' + item.endAt.substring(0, 10)
            } else if (item.startAt && !item.endAt) {
                return item.startAt.substring(0, 10);
            } else if (item.endAt && !item.startAt) {
                return item.endAt.substring(0, 10);
            }
        }
    },
    computed: {
        subTypeOptions: function() {
            if (this.request.board && ['half_extra_breakfast', 'half'].includes(this.request.board)) {
                return ['Frühstück', 'Abendessen'];
            }
            if (this.request.board && ['full_extra_breakfast', 'full'].includes(this.request.board)) {
                return ['Frühstück', 'Mittagessen', 'Abendessen'];
            }
            return []
        },
        orderID:         function () { return this.$store.state.orderID; },
        dates: function() {
            if (this.request && this.request.startAt && this.request.endAt) {
                return listOfDays((this.request.startAt), (this.request.endAt)).map(item => fullYear(item));
            }
            return [];
        },
        providerType: function () { return this.request && this.request.provider ? this.request.provider.providerType : '';},
        isHotel: function () {
            return this.request.requestType === 'hotel'
        }
    },
    watch: {
        date: {
            handler: function() {
                this.checked = '';
                if(this.date) {
                    this.getTripDetails();
                }
            }
        },
        subType: {
            handler: function() {
                this.checked = '';
                if(this.subType) {
                    this.getTripDetails();
                }
            }
        }
    },

    filters: {
        fullYear
    },

    components: {
        Multiselect,
        MenuForm
    }


}
</script>
