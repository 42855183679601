<template>
    <multiselect
        v-model                = "otherServiceSelected"
        v-bind:options         = "subTitleOnly ? items2 : items"
        v-bind:loading         = "isLoadingItems"
        v-bind:allow-empty     = "false"
        v-bind:disabled        = "!otherServiceTypeID"
        v-bind:custom-label    = "subTitleOnly ? otherSubTitleOnlyLabel : otherLabel"
        v-bind:internal-search = "false"

        placeholder    = "Zusatzleistung auswählen"
        track-by       = "id"
        group-values   = "items"
        group-label    = "type"
        select-label   = ""
        deselect-label = "Löschen"

        v-on:search-change = "otherServiceSearch"
    >
        <template slot="noResult">Keine passenden Leistungsträger</template>
        <template slot="option" slot-scope="props">

            <div class="columns">
                <div class="column">
                    {{subTitleOnly ? otherSubTitleOnlyLabel(props.option) : otherLabel(props.option)}}
                    {{props.option.menu && props.option.menu.price ? '(' + props.option.menu.price.formatted + ')' : ''}}
                    <div style="right:-10px; position:relative;" v-if="props.option.menu && isHover === props.option.id">
                        <div v-html="props.option.menu.content"></div>
                    </div>
                </div>
                <button
                    v-on:mouseenter="isHover = props.option.id"
                    v-on:mouseleave="isHover = null"
                    class="column is-narrow"
                    v-if="props.option.menu && props.option.menu.content"
                    title="Preview"
                ><i class="material-icons">search</i></button>
            </div>


        </template>
        <span slot="caret">
            <a
                class  = "multiselect-crud-link"
                href   = "/others?add"
                title  = "Fügen Sie eine neue Leistungsträger hinzu"
                target = "_blank"
            ><i class="material-icons">add</i></a>
        </span>
    </multiselect>
</template>


<script>
import axios           from 'axios';
import debounce        from 'lodash/debounce';
import Multiselect     from '@components/form/Multiselect';
import { notifyError } from '@components/Notification';
import mixins from "@orders/placeholders/components/forms/mixins";
import {getAxios} from "@api";
import Popover from "@components/Popover.vue";


const emptyObject = { id: -1, provider: { name: 'No-Name' } };


export default {
    mixins: [mixins],
    props: {
        value: {},
        otherServiceTypeID: { required: true },
        providerID: { required: false },
        agencyID: {required: false},
        agencyExist: {default: null},
        placeholderInfo: {  default: () => ({}) },
        subTitleOnly: {default: false}
    },


    data: function () {
        return {
            isHover: null,
            items: [
                {
                    type: 'No-Name',
                    items: [emptyObject]
                },
                {
                    type: 'Leistungsträger',
                    items: []
                }
            ],
            items2: [
                {
                    type: 'Zusatztitel',
                    items: []
                }
            ],

            queryString:          '',
            otherServiceSelected: null,
            isLoadingItems:       false
        }
    },


    methods: {

        otherSubTitleOnlyLabel (other) {
            let subtitle = '';
            subtitle += other.subtitle ? other.subtitle : 'ohne Zusatztitel';
            return subtitle
        },

        otherLabel: function (other) {
            let subtitle = '';

            subtitle += other.agency ? ' (' + other.agency.name + ')' : '';
            subtitle += other.subtitle ? ' (' + other.subtitle + ')' : '';
            if (other.provider) {
                subtitle += other.provider.place && other.provider.place.destination  ? ' (' + other.provider.place.destination.name + ')' : '';
                return other.provider.name + subtitle ;

            } else {
                return 'No-Name' + subtitle;
            }
        },


        otherServiceSearch: debounce(function (query, isTriggerAction = false) {
            if (this.otherServiceTypeID || isTriggerAction) {
                this.queryString = query;

                this.isLoadingItems = true;
                this.callCancelRequest();



                Promise.all([
                    getAxios('other_services', {
                        'provider[exists]': true,
                        'agency[exists]': this.agencyExist,
                        'provider[discriminator]': ['train', 'ferry', 'airline'],
                        'provider.name': query,
                        'otherServiceType.id': this.otherServiceTypeID,
                        'provider.id': this.providerID,
                        'agency.id': this.agencyID,
                        '_groups': ['provider_read', 'agency_read', 'place_read', 'area_read', 'destination_read']
                    }),
                    getAxios('other_services', {
                        'provider[exists]': true,
                        'agency[exists]': this.agencyExist,
                        'provider.name': query,
                        'otherServiceType.id': this.otherServiceTypeID,
                        'provider.id': this.providerID,
                        'agency.id': this.agencyID,

                        ...this.placeholderInfo.area && {
                            'providerWithPlace.place.area.id': this.placeholderInfo.area.id,
                        },
                        ...this.placeholderInfo.place && {
                            'providerWithPlace.place.id': this.placeholderInfo.place.id,
                        },
                        ...this.placeholderInfo.destination && {
                            'providerWithPlace.place.destination.id': this.placeholderInfo.destination.id,
                        },
                        '_groups': ['provider_read', 'agency_read', 'place_read', 'area_read', 'destination_read', 'provider_other_service:menu', 'menu', 'money_read']
                    }),
                    /*getAxios('other_services', {
                        'provider[exists]': false,
                        'otherServiceType.id': this.otherServiceTypeID,
                        'provider.id': this.providerID,
                        '_groups': ['provider_read', 'agency_read', 'place_read', 'area_read', 'destination_read']
                    }),*/
                ]).then(([providerWithNoPlace, withProvider]) => {
                    if(this.subTitleOnly) {
                        this.items2[0].items = [
                            ...withProvider.data,
                            ...providerWithNoPlace.data,
                        ];
                    } else {
                        this.items[1].items = [
                            ...withProvider.data,
                            ...providerWithNoPlace.data,
                        ];
                    }

                    }, error => { if(!axios.isCancel(error)) {
                        notifyError('Die Anbieter konnten nicht geladen werden! Server error!');
                    } })
                    .then(() => { this.isLoadingItems = false; });
            }
        }, 900)
    },


    created: function () {
        this.otherServiceSearch(this.queryString, true);
    },


    watch: {
        otherServiceTypeID: function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.otherServiceSearch(this.queryString, true);
            }
        },

        providerID: function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.otherServiceSearch(this.queryString, true);
            }
        },

        agencyID: function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.otherServiceSearch(this.queryString, true);
            }
        },

        placeholderInfo: function (newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.otherServiceSearch(this.queryString, true);
            }
        },


        value: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    if (this.value) {
                        this.otherServiceSelected = JSON.parse(JSON.stringify(this.value));

                    } else {
                        this.otherServiceSelected = emptyObject;
                    }
                }
            },
            deep: true,
            immediate: true
        },


        otherServiceSelected: {
            handler: function (value) {
                this.$emit('input', value.id !== -1 ? value : null);
            },
            deep: true
        }
    },


    components: {
        Popover,
        Multiselect
    }
}
</script>
